import axios from "axios";
import { getItem } from "../utils/storage";
import { baseURL } from "../config/config";
import { getDataSec, postDataSec, putDataSec } from "./Api";

export const getIAMUser = async () => {
    try {
        const response = await getDataSec(`${baseURL}sellerUser`);
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const changeActiveIAM = async (id, active) => {
    try {
        const response = await postDataSec(`${baseURL}sellerUser/active`, {
            id,
            active: active,
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const updateUser = async (data) => {
    try {
        const response = await putDataSec(`${baseURL}sellerUser`, data);
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const createUser = async (data) => {
    try {
        const response = await postDataSec(`${baseURL}sellerUser`, data);
        return response;
    } catch (error) {
        console.log(error);
    }
};
