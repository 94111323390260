import React, { useEffect, useState } from "react";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import "./smartlist.css";
import { Button } from "@mui/material";

const SmartList = () => {
    const [smartList, setSmartList] = useState([]);
    const [selecedSmartList, setSelectedSmartList] = useState(false);
    const [currentSmartListDetails, setCurrentSmartListDetails] =
        useState(null);

    const [tree, setTree] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selectedLeafCategory, setSelectedLeafCategory] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedSubBrand, setSelectedSubBrand] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [leafCategories, setLeafCategories] = useState([]);
    const [brandName, setBrandName] = useState([]);
    const [subBrands, setSubBrands] = useState([]);

    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [minDiscount, setMinDiscount] = useState("");
    const [maxDiscount, setMaxDiscount] = useState("");

    const getSmartList = async () => {
        try {
            const result = await getDataSec(baseURL + "/smartlist");
            if (result.data) {
                console.log(result.data);
                setSmartList(result.data);
            } else {
                toast.error("Unable to fetch smartlist");
                console.log(result.message);
            }
        } catch (err) {
            console.log("Error in fetching smartlist:", err);
            toast.error("Error while fetching smartlist");
        }
    };

    useEffect(() => {
        getSmartList();
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    // const copyTagLink = (item) => {
    //     navigator.clipboard.writeText(`https://aapkabazar.co/tags/${item.id}/${item._name}`).then(() => {
    //         toast.success("Copied to clipboard");
    //     })
    // }

    const populateEditFields = (details) => {
        console.log("Try to populate edit", details);
        if (details && details.data.config) {
            setSelectedCategory(details.data.config.category || "");
            setSelectedSubCategory(details.data.config.subCategory || "");
            setSelectedLeafCategory(details.data.config.leafCategory || "");
            setSelectedBrand(details.data.config.brand || "");
            setSelectedSubBrand(details.data.config.subBrand || "");

            setMinPrice(details.data.config.minPrice || "");
            setMaxPrice(details.data.config.maxPrice || "");
            setMinDiscount(details.data.config.minDiscount || "");
            setMaxDiscount(details.data.config.maxDiscount || "");

            if (details.data.category) {
                fetchSubCategories(details.data.category);
            }

            if (details.data.subCategory) {
                fetchLeafCategories(details.data.subCategory);
            }

            if (details.data.brand) {
                fetchSubBrands(details.data.brand);
            }
        }
    };

    const fetchSubCategories = (categoryId) => {
        getDataSec(
            `${baseURL}/category/subcategory?categoryId=${categoryId}`
        ).then((response) => {
            if (response && response.success) {
                const filteredItems = response?.category?.filter(
                    (item) => !item.isLeaf
                );
                setSubCategories(filteredItems);
            }
        });
    };

    const fetchLeafCategories = (subCategoryId) => {
        getDataSec(
            `${baseURL}/category/subcategory?categoryId=${subCategoryId}`
        ).then((response) => {
            if (response && response.success) {
                setLeafCategories(response?.category);
            }
        });
    };

    const fetchSubBrands = (brandId) => {
        getDataSec(`${baseURL}/brand?brandId=${brandId}`).then((response) => {
            if (response && response.success) {
                setSubBrands(response?.brand?.subBrands || []);
            }
        });
    };

    const modifySmartList = async () => {
        try {
            if (!currentSmartListDetails.data?._id) {
                if (!currentSmartListDetails.data.name) {
                    toast.error("smart list name is required");
                    return;
                }
                const data = {
                    isActive: currentSmartListDetails.data.isActive,
                    name: currentSmartListDetails.data.name,
                    products:
                        currentSmartListDetails?.products?.map(
                            (item) => item.id
                        ) || [],
                    category: selectedCategory || undefined,
                    subCategory: selectedSubCategory || undefined,
                    leafCategory: selectedLeafCategory || undefined,
                    brand: selectedBrand || undefined,
                    subBrand: selectedSubBrand || undefined,
                    minPrice: minPrice || undefined,
                    maxPrice: maxPrice || undefined,
                    minDiscount: minDiscount || undefined,
                    maxDiscount: maxDiscount || undefined,
                };
                const result = await postDataSec(
                    baseURL + "/createSmartList",
                    data
                );
                if (result.success) {
                    toast.success(result.message);
                    setSelectedSmartList(false);
                    setCurrentSmartListDetails(null);
                    getSmartList();
                } else {
                    toast.error(result.message);
                }
                return;
            }

            if (selecedSmartList._id && selecedSmartList._id === null) {
                toast.error("Smart List ID not found");
            }

            const data = {
                isActive: currentSmartListDetails.data.isActive,
                smartlist_name: currentSmartListDetails.data.name,
                products: currentSmartListDetails.products.map(
                    (item) => item.id
                ),
                smartlist_id: currentSmartListDetails.data._id,
                category: selectedCategory || undefined,
                subCategory: selectedSubCategory || undefined,
                leafCategory: selectedLeafCategory || undefined,
                brand: selectedBrand || undefined,
                subBrand: selectedSubBrand || undefined,
                minPrice: minPrice || undefined,
                maxPrice: maxPrice || undefined,
                minDiscount: minDiscount || undefined,
                maxDiscount: maxDiscount || undefined,
            };

            const result = await putDataSec(baseURL + "/updateSmartList", data);
            console.log("res", result);
            if (result.success) {
                toast.success(result.message);
                setCurrentSmartListDetails(null);
                setSelectedSmartList(false);
                getSmartList();
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log("error in updating smartList", err);
            toast.error("Error in updating Smart List");
        }
    };

    useEffect(() => {
        const getCategories = async () => {
            const result = await getDataSec(baseURL + "/brands");
            if (result.success) {
                setBrandName(result.brand);
            } else {
                console.log("Brand Names cannot be fetched");
            }
            const result2 = await getDataSec(baseURL + "/category/tree/v2");
            if (result2.success === true) {
                setTree(result2.category);
            } else {
                console.log("error in fetching categories", result.message);
            }
        };
        getCategories();
        getDataSec(`${baseURL}/category`).then((response) => {
            try {
                if (response?.data) {
                    setBrandName(response.data);
                }
            } catch (err) {
                console.log(err);
            }
        });
        getDataSec(`${baseURL}/brands`).then((response) => {
            if (response?.data) {
                setBrandName(response?.data);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            fetchSubCategories(selectedCategory);
        } else {
            setSubCategories([]);
            setLeafCategories([]);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedSubCategory) {
            fetchLeafCategories(selectedSubCategory);
        } else {
            setLeafCategories([]);
        }
    }, [selectedSubCategory]);

    useEffect(() => {
        if (selectedBrand) {
            fetchSubBrands(selectedBrand);
        } else {
            setSubBrands([]);
        }
    }, [selectedBrand]);

    return (
        <div style={{ display: "flex", flex: 1, padding: 20, gap: 20 }}>
            <div className="tagListContainer">
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <h3>Smart List</h3>
                    <button
                        onClick={() => {
                            setSelectedSmartList(true);
                            setCurrentSmartListDetails({
                                data: { isActive: true, name: "" },
                                products: [],
                            });
                            setSelectedCategory("");
                            setSelectedSubCategory("");
                            setSelectedLeafCategory("");
                            setSelectedBrand("");
                            setSelectedSubBrand("");
                        }}
                        style={{
                            border: "1px solid #eee",
                            padding: "10px 20px",
                            width: 120,
                            marginBottom: 10,
                            backgroundColor: "#ccc",
                            borderRadius: 8,
                            alignSelf: "flex-end",
                        }}
                    >
                        Add New +
                    </button>
                </div>

                <table className="smartListTable">
                    <tbody>
                        {smartList.map((item, index) => (
                            <tr key={index}>
                                <td
                                    style={{
                                        textTransform: "capitalize",
                                        paddingRight: 10,
                                        width: 40,
                                    }}
                                >
                                    {item.id}
                                </td>
                                <td
                                    style={{
                                        textTransform: "capitalize",
                                        paddingRight: 10,
                                        width: 40,
                                        color: !item?.isActive
                                            ? "red"
                                            : "green",
                                    }}
                                >
                                    {item.isActive ? "On" : "Off"}
                                </td>
                                <td
                                    colSpan={2}
                                    style={{
                                        textTransform: "capitalize",
                                        paddingRight: 10,
                                        width: 200,
                                    }}
                                >
                                    {item.name}
                                </td>
                                <td>
                                    <button
                                        onClick={() => {
                                            getDataSec(
                                                `${baseURL}/getSmartList/${item._id}`
                                            ).then((res) => {
                                                console.log(res);
                                                if (res && res.success) {
                                                    setSelectedSmartList(true);
                                                    setCurrentSmartListDetails(
                                                        res
                                                    );
                                                    populateEditFields(res);
                                                }
                                            });
                                        }}
                                        style={{
                                            border: "1px solid green",
                                            backgroundColor: "#00aa0022",
                                            padding: "10px 20px",
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "green",
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>

                                {/* <td>
                                    <button
                                        onClick={() => copyTagLink(item)}
                                        style={{
                                            border: '1px solid blue',
                                            backgroundColor: '#0000aa22',
                                            padding: '10px 20px',
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "blue"
                                        }}
                                    >
                                        Copy
                                    </button>
                                </td> */}

                                <td>
                                    <button
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are you sure you want to delete this tag?"
                                                )
                                            ) {
                                                getDataSec(
                                                    baseURL +
                                                        "/deleteSmartList/" +
                                                        item._id
                                                )
                                                    .then((res) => {
                                                        if (
                                                            res &&
                                                            res.success
                                                        ) {
                                                            toast.success(
                                                                res.message
                                                            );
                                                            setSelectedSmartList(
                                                                false
                                                            );
                                                            setCurrentSmartListDetails(
                                                                null
                                                            );
                                                            getSmartList();
                                                        } else {
                                                            toast.error(
                                                                res?.message ||
                                                                    "Something went wrong"
                                                            );
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        toast.error(
                                                            "Client Error"
                                                        );
                                                    });
                                            }
                                        }}
                                        style={{
                                            border: "1px solid red",
                                            backgroundColor: "#ff000022",
                                            padding: "10px 20px",
                                            width: 80,
                                            marginBottom: 10,
                                            borderRadius: 8,
                                            color: "red",
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selecedSmartList && (
                <div className="tagEditorContainer">
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                gap: 20,
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 20,
                                }}
                            >
                                <label>Smart List Name</label>
                                <input
                                    onChange={(e) => {
                                        setCurrentSmartListDetails((prev) => ({
                                            ...prev,
                                            data: {
                                                ...prev.data,
                                                name: e.target.value,
                                            },
                                        }));
                                    }}
                                    value={currentSmartListDetails.data.name}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 20,
                                }}
                            >
                                <label>Status</label>
                                <input
                                    onChange={(e) => {
                                        setCurrentSmartListDetails((prev) => ({
                                            ...prev,
                                            data: {
                                                ...prev.data,
                                                isActive: e.target.checked,
                                            },
                                        }));
                                    }}
                                    type="checkbox"
                                    checked={
                                        currentSmartListDetails.data.isActive
                                    }
                                />
                            </div>
                            <select
                                value={selectedCategory}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value);
                                    setSelectedSubCategory("");
                                    setSelectedLeafCategory("");
                                }}
                                style={{ padding: "8px", borderRadius: "5px" }}
                            >
                                <option value="">Select Category</option>
                                {tree
                                    .filter((item) => item.isActive)
                                    .map((category) => (
                                        <option
                                            key={category._id}
                                            value={category._id}
                                        >
                                            {category.name}
                                        </option>
                                    ))}
                            </select>

                            <select
                                value={selectedSubCategory}
                                onChange={(e) => {
                                    setSelectedSubCategory(e.target.value);
                                    setSelectedLeafCategory("");
                                }}
                                style={{ padding: "8px", borderRadius: "5px" }}
                            >
                                <option value="">Select Sub-Category</option>

                                {subCategories.map((sub) => (
                                    <option key={sub._id} value={sub._id}>
                                        {sub.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedLeafCategory}
                                onChange={(e) => {
                                    setSelectedLeafCategory(e.target.value);
                                }}
                                style={{ padding: "8px", borderRadius: "5px" }}
                            >
                                <option value="">Select Leaf Category</option>
                                {leafCategories.map((leaf) => (
                                    <option key={leaf._id} value={leaf._id}>
                                        {leaf.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedBrand}
                                onChange={(e) => {
                                    setSelectedBrand(e.target.value);
                                    setSelectedSubBrand("");
                                }}
                                style={{ padding: "8px", borderRadius: "5px" }}
                            >
                                <option value="">Select Brand</option>
                                {brandName.map((brand) => (
                                    <option key={brand._id} value={brand._id}>
                                        {brand.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedSubBrand}
                                onChange={(e) => {
                                    setSelectedSubBrand(e.target.value);
                                }}
                                style={{ padding: "8px", borderRadius: "5px" }}
                            >
                                <option value="">Select Sub-Brand</option>
                                {subBrands.map((subBrand) => (
                                    <option
                                        key={subBrand._id}
                                        value={subBrand._id}
                                    >
                                        {subBrand.name}
                                    </option>
                                ))}
                            </select>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 20,
                                }}
                            >
                                <label>Price Range</label>
                                <div style={{ display: "flex", gap: 10 }}>
                                    <input
                                        type="number"
                                        placeholder="Min Price"
                                        value={minPrice}
                                        onChange={(e) =>
                                            setMinPrice(e.target.value)
                                        }
                                        style={{
                                            width: 100,
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                        }}
                                    />
                                    <span>to</span>
                                    <input
                                        type="number"
                                        placeholder="Max Price"
                                        value={maxPrice}
                                        onChange={(e) =>
                                            setMaxPrice(e.target.value)
                                        }
                                        style={{
                                            width: 100,
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 20,
                                }}
                            >
                                <label>Discount Range (%)</label>
                                <div style={{ display: "flex", gap: 10 }}>
                                    <input
                                        type="number"
                                        placeholder="Min %"
                                        value={minDiscount}
                                        onChange={(e) =>
                                            setMinDiscount(e.target.value)
                                        }
                                        min="0"
                                        max="100"
                                        style={{
                                            width: 100,
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                        }}
                                    />
                                    <span>to</span>
                                    <input
                                        type="number"
                                        placeholder="Max %"
                                        value={maxDiscount}
                                        onChange={(e) =>
                                            setMaxDiscount(e.target.value)
                                        }
                                        min="0"
                                        max="100"
                                        style={{
                                            width: 100,
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: 30,
                                flexDirection: "row",
                                gap: 20,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                    setSelectedSmartList(false);
                                    setCurrentSmartListDetails(null);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                    modifySmartList();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SmartList;
