import React from "react";

const Container = ({ children }) => {
    return (
        <div
            style={{
                padding: "20px",
                borderRadius: "20px",
                margin: "20px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
            }}
        >
            {children}
        </div>
    );
};

export default Container;
