import React from "react";

const Button = (props) => {
    return (
        <button
            {...props}
            style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "blue",
                color: "white",
                cursor: "pointer",
            }}
        ></button>
    );
};

export default Button;
