import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL, liveURL } from "../../config/config";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import axios from "axios";

interface ProductRow {
    id: string;
    name: string;
    description: string;
    rootCategory: any;
    subCategory: any;
    leafCategory: any;
    brand: any;
    subBrand: any;
    recommendedAttribute: string;
    shipping: { weight: string | number };
    hsnCode: string;
    barCode: string;
    price: string | number;
    sellPrice: string | number;
    minSellPrice: string | number;
    seo: {
        metaTitle: string;
        metaKeywords: string;
        metaDescription: string;
    };
    status: "pending" | "submitting" | "submitted" | "error";
    error?: string;
}

interface ColumnWidth {
    [key: string]: number;
}

export const SingleProductNewPageTwo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductRow[]>([]);
    const [subCategories, setSubCategories] = useState<any>([]);
    const [leafCategories, setLeafCategories] = useState<any>();
    const [tree, setTree] = useState<any>([]);
    const [brands, setBrands] = useState<any>([]);
    const [subBrands, setSubBrands] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [isSubmittingAll, setIsSubmittingAll] = useState(false);
    const [submissionProgress, setSubmissionProgress] = useState({
        current: 0,
        total: 0,
    });
    const [columnWidths, setColumnWidths] = useState<ColumnWidth>({
        status: 80,
        name: 150,
        description: 200,
        category: 150,
        subCategory: 150,
        leafCategory: 150,
        brand: 150,
        subBrand: 150,
        attribute: 150,
        weight: 100,
        hsnCode: 120,
        barCode: 120,
        mrp: 100,
        sellPrice: 100,
        minSellPrice: 120,
        metaTitle: 150,
        metaKeywords: 150,
        metaDescription: 200,
        actions: 100,
    });
    const [resizing, setResizing] = useState<string | null>(null);
    const [startX, setStartX] = useState<number>(0);
    const [startWidth, setStartWidth] = useState<number>(0);
    const tableRef = useRef<HTMLTableElement>(null);

    const resetStateValues = () => {
        setProducts([]);
        setSubCategories([]);
        setLeafCategories(undefined);
        setTree([]);
        setBrands([]);
        setSubBrands([]);
        setLoading(false);
    };

    const initializeData = () => {
        setLoading(true);
        getDataSec(`${baseURL}/category/tree/v2`)
            .then((res2) => {
                if (res2.success) {
                    getDataSec(`${baseURL}/brands?start=&end=`)
                        .then((res3) => {
                            if (res3 && res3.success) {
                                setBrands(res3.brand);
                            }
                        })
                        .catch((err) => {
                            toast.error("Error in fetching brands");
                        });
                    setTree(res2.category);
                }
            })
            .catch((err) => {
                toast.error("Error in fetching categories");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        initializeData();
        // Add a default row when component mounts
        const defaultRow: ProductRow = {
            id: Date.now().toString(),
            name: "",
            description: "",
            rootCategory: {},
            subCategory: {},
            leafCategory: {},
            brand: {},
            subBrand: {},
            recommendedAttribute: "",
            shipping: { weight: "" },
            hsnCode: "",
            barCode: "",
            price: "",
            sellPrice: "",
            minSellPrice: "",
            seo: {
                metaTitle: "",
                metaKeywords: "",
                metaDescription: "",
            },
            status: "pending",
        };
        setProducts([defaultRow]);
    }, []);

    const validations = (product: ProductRow) => {
        if (!product?.name) return "Please enter product name";
        if (!product?.description) return "Please enter product description";
        if (!product?.rootCategory?._id) return "Please select root category";
        if (!product?.subCategory?._id) return "Please select sub category";
        if (!product?.leafCategory?._id) return "Please select leaf category";
        if (!product?.brand?.id) return "Please select brand";
        if (!product?.subBrand?.id) return "Please select sub brand";
        if (!product?.recommendedAttribute) return "Please enter attribute";
        if (!product?.shipping?.weight || product?.shipping?.weight === 0)
            return "Please enter weight";
        if (!product?.hsnCode) return "Please enter HSN code";
        if (!product?.barCode) return "Please enter barcode";
        if (!product?.price) return "Please enter MRP";
        if (!product?.sellPrice) return "Please enter sell price";
        if (!product?.minSellPrice) return "Please enter min sell price";
        if (!product?.seo?.metaTitle) return "Please enter meta title";
        if (!product?.seo?.metaKeywords) return "Please enter meta keywords";
        if (!product?.seo?.metaDescription)
            return "Please enter meta description";
        return null;
    };

    const handleSubmitRow = async (index: number): Promise<boolean> => {
        const product = products[index];
        const validationError = validations(product);

        if (validationError) {
            setProducts((prev) => {
                const newProducts = [...prev];
                toast.error(validationError);
                newProducts[index] = {
                    ...newProducts[index],
                    status: "error",
                    error: validationError,
                };
                return newProducts;
            });
            return false;
        }

        setProducts((prev) => {
            const newProducts = [...prev];
            newProducts[index] = {
                ...newProducts[index],
                status: "submitting",
            };
            return newProducts;
        });

        try {
            const response = await postDataSec(`${baseURL}/product/add`, {
                barCode: product.barCode,
                name: product.name,
                description: product.description,
                recommendedAttribute: product.recommendedAttribute,
                mrp: product.price,
                price: product.sellPrice,
                purchasePrice: 0,
                minSellPrice: product.minSellPrice,
                membershipPrice: 0,
                hsnCode: product.hsnCode,
                gst: 0,
                urlKey: product.name
                    .replace(/[^\w\s]|_/g, "")
                    .replace(/\s+/g, "-")
                    .toLowerCase(),
                isOrder: true,
                isLastBuying: false,
                isSubscription: false,
                isMorningBuy: false,
                brand: {
                    name: product.brand?.name,
                    _id: product.brand?.id,
                    id: product.brand?.id,
                },
                subBrand: {
                    name: product.subBrand?.name,
                    _id: product.subBrand?.id,
                    id: product.subBrand?.id,
                },
                rootCatId: product.rootCategory._id,
                subCatId: product.subCategory._id,
                leafCatId: product.leafCategory._id,
                images: [],
                tags: [],
                seo: {
                    metaTitle: product.seo?.metaTitle,
                    metaDescription: product.seo?.metaDescription,
                    metaKeywords: product.seo?.metaKeywords,
                },
                shipping: {
                    dimensions: { height: 0, length: 0, width: 0 },
                    weight: product.shipping.weight,
                },
                competitor: {
                    grofers: {
                        productId: null,
                        lastPrice: null,
                        merchantId: null,
                    },
                    milkbasket: { productId: null, lastPrice: null },
                    bigbasket: { productId: null, lastPrice: null },
                    grocio: { productId: null, lastPrice: null },
                },
                parentId: "",
                isParent: true,
            });

            if (response.success) {
                setProducts((prev) => {
                    const newProducts = [...prev];
                    newProducts[index] = {
                        ...newProducts[index],
                        status: "submitted",
                    };
                    return newProducts;
                });
                return true;
            } else {
                throw new Error("Submission failed");
            }
        } catch (error) {
            setProducts((prev) => {
                const newProducts = [...prev];
                newProducts[index] = {
                    ...newProducts[index],
                    status: "error",
                    error: error.message || "Failed to submit",
                };
                return newProducts;
            });
            return false;
        }
    };

    const handleSubmitAll = async () => {
        if (products.length === 0) {
            toast.error("Please add at least one product");
            return;
        }

        setIsSubmittingAll(true);
        setSubmissionProgress({ current: 0, total: products.length });

        let successCount = 0;
        let failureCount = 0;

        for (let i = 0; i < products.length; i++) {
            if (products[i].status !== "submitted") {
                const success = await handleSubmitRow(i);
                if (success) {
                    successCount++;
                } else {
                    failureCount++;
                }
                setSubmissionProgress((prev) => ({ ...prev, current: i + 1 }));
            }
        }

        setIsSubmittingAll(false);
        toast.success(
            `Submission complete: ${successCount} succeeded, ${failureCount} failed`
        );
    };

    const addNewRow = () => {
        const newRow: ProductRow = {
            id: Date.now().toString(),
            name: "",
            description: "",
            rootCategory: {},
            subCategory: {},
            leafCategory: {},
            brand: {},
            subBrand: {},
            recommendedAttribute: "",
            shipping: { weight: "" },
            hsnCode: "",
            barCode: "",
            price: "",
            sellPrice: "",
            minSellPrice: "",
            seo: {
                metaTitle: "",
                metaKeywords: "",
                metaDescription: "",
            },
            status: "pending",
        };
        setProducts((prev) => [...prev, newRow]);
    };

    const handleMouseDown = (e: React.MouseEvent, columnId: string) => {
        setResizing(columnId);
        setStartX(e.pageX);
        setStartWidth(columnWidths[columnId]);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (resizing === null) return;

        const diff = e.pageX - startX;
        const newWidth = Math.max(50, startWidth + diff); // Minimum width of 50px

        setColumnWidths((prev) => ({
            ...prev,
            [resizing]: newWidth,
        }));
    };

    const handleMouseUp = () => {
        setResizing(null);
    };

    useEffect(() => {
        if (resizing) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
            return () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            };
        }
    }, [resizing, startX, startWidth]);

    const handleDownloadCSV = () => {
        // Create CSV headers
        const headers = [
            "Name",
            "Description",
            "Root Category ID",
            "Root Category Name",
            "Sub Category ID",
            "Sub Category Name",
            "Leaf Category ID",
            "Leaf Category Name",
            "Brand ID",
            "Brand Name",
            "Sub Brand ID",
            "Sub Brand Name",
            "Attribute",
            "Weight",
            "HSN Code",
            "Barcode",
            "MRP",
            "Sell Price",
            "Min Sell Price",
            "Meta Title",
            "Meta Keywords",
            "Meta Description",
        ];

        // Transform products data into CSV format
        const csvData = products.map((product) => {
            // Find category names from tree
            const rootCategory = tree.find(
                (cat) => cat._id === product.rootCategory?._id
            );
            const subCategory = rootCategory?.children?.find(
                (cat) => cat._id === product.subCategory?._id
            );
            const leafCategory = subCategory?.children?.find(
                (cat) => cat._id === product.leafCategory?._id
            );

            return [
                product.name,
                product.description,
                product?.rootCategory?._id || "",
                rootCategory?.name || "",
                product.subCategory?._id || "",
                subCategory?.name || "",
                product.leafCategory?._id || "",
                leafCategory?.name || "",
                product.brand?.id || "",
                product.brand?.name || "",
                product.subBrand?.id || "",
                product.subBrand?.name || "",
                product.recommendedAttribute,
                product.shipping.weight,
                product.hsnCode,
                product.barCode,
                product.price,
                product.sellPrice,
                product.minSellPrice,
                product.seo?.metaTitle,
                product.seo?.metaKeywords,
                product.seo?.metaDescription,
            ];
        });

        // Combine headers and data
        const csvContent = [
            headers.join(","),
            ...csvData.map((row) => row.map((cell) => `"${cell}"`).join(",")),
        ].join("\n");

        // Create and trigger download
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
            "download",
            `products_${new Date().toISOString().split("T")[0]}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div
            className="excel-like-container"
            style={{
                padding: 20,
                backgroundColor: "white",
                position: "relative",
                minHeight: "100vh",
                paddingBottom: "80px", // Space for floating buttons
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 20,
                }}
            >
                <h2>Add Multiple Products</h2>
            </div>

            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                    }}
                >
                    <CircularProgress size={24} />
                    <span style={{ marginLeft: 10 }}>Loading...</span>
                </div>
            ) : (
                <div style={{ overflowX: "auto", marginBottom: "80px" }}>
                    <table
                        ref={tableRef}
                        style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            tableLayout: "fixed",
                            border: "1px solid #ddd",
                        }}
                    >
                        <colgroup>
                            <col style={{ width: columnWidths.status }} />
                            <col style={{ width: columnWidths.name }} />
                            <col style={{ width: columnWidths.description }} />
                            <col style={{ width: columnWidths.category }} />
                            <col style={{ width: columnWidths.subCategory }} />
                            <col style={{ width: columnWidths.leafCategory }} />
                            <col style={{ width: columnWidths.brand }} />
                            <col style={{ width: columnWidths.subBrand }} />
                            <col style={{ width: columnWidths.attribute }} />
                            <col style={{ width: columnWidths.weight }} />
                            <col style={{ width: columnWidths.hsnCode }} />
                            <col style={{ width: columnWidths.barCode }} />
                            <col style={{ width: columnWidths.mrp }} />
                            <col style={{ width: columnWidths.sellPrice }} />
                            <col style={{ width: columnWidths.minSellPrice }} />
                            <col style={{ width: columnWidths.metaTitle }} />
                            <col style={{ width: columnWidths.metaKeywords }} />
                            <col
                                style={{ width: columnWidths.metaDescription }}
                            />
                            <col style={{ width: columnWidths.actions }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.status,
                                        position: "relative",
                                    }}
                                >
                                    Status
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "status")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.name,
                                        position: "relative",
                                    }}
                                >
                                    Name
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "name")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.description,
                                        position: "relative",
                                    }}
                                >
                                    Description
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "description")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.category,
                                        position: "relative",
                                    }}
                                >
                                    Category
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "category")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.subCategory,
                                        position: "relative",
                                    }}
                                >
                                    Sub Category
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "subCategory")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.leafCategory,
                                        position: "relative",
                                    }}
                                >
                                    Leaf Category
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "leafCategory")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.brand,
                                        position: "relative",
                                    }}
                                >
                                    Brand
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "brand")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.subBrand,
                                        position: "relative",
                                    }}
                                >
                                    Sub Brand
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "subBrand")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.attribute,
                                        position: "relative",
                                    }}
                                >
                                    Attribute
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "attribute")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.weight,
                                        position: "relative",
                                    }}
                                >
                                    Weight
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "weight")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.hsnCode,
                                        position: "relative",
                                    }}
                                >
                                    HSN Code
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "hsnCode")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.barCode,
                                        position: "relative",
                                    }}
                                >
                                    Barcode
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "barCode")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.mrp,
                                        position: "relative",
                                    }}
                                >
                                    MRP
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "mrp")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.sellPrice,
                                        position: "relative",
                                    }}
                                >
                                    Sell Price
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "sellPrice")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.minSellPrice,
                                        position: "relative",
                                    }}
                                >
                                    Min Sell Price
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "minSellPrice")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.metaTitle,
                                        position: "relative",
                                    }}
                                >
                                    Meta Title
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "metaTitle")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.metaKeywords,
                                        position: "relative",
                                    }}
                                >
                                    Meta Keywords
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "metaKeywords")
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.metaDescription,
                                        position: "relative",
                                    }}
                                >
                                    Meta Description
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(
                                                e,
                                                "metaDescription"
                                            )
                                        }
                                    />
                                </th>
                                <th
                                    style={{
                                        ...headerStyle,
                                        width: columnWidths.actions,
                                        position: "relative",
                                    }}
                                >
                                    Actions
                                    <div
                                        style={resizeHandleStyle}
                                        onMouseDown={(e) =>
                                            handleMouseDown(e, "actions")
                                        }
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index) => (
                                <tr key={product.id} style={rowStyle}>
                                    <td style={cellStyle}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%",
                                            }}
                                        >
                                            {product.status === "pending" &&
                                                "⏳"}
                                            {product.status === "submitting" &&
                                                "🔄"}
                                            {product.status === "submitted" &&
                                                "✅"}
                                            {product.status === "error" && (
                                                <div title={product.error}>
                                                    ❌
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.name}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    name: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <textarea
                                            style={textareaStyle}
                                            value={product.description}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    description: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <select
                                            style={selectStyle}
                                            value={
                                                product.rootCategory?._id || ""
                                            }
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    rootCategory: {
                                                        _id: e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                                tree.forEach((element) => {
                                                    if (
                                                        element._id ===
                                                        e.target.value
                                                    ) {
                                                        setSubCategories(
                                                            element.children
                                                        );
                                                        setLeafCategories([]);
                                                    }
                                                });
                                            }}
                                        >
                                            <option value="">
                                                Select Category
                                            </option>
                                            {tree?.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={cellStyle}>
                                        <select
                                            style={selectStyle}
                                            value={
                                                product.subCategory?._id || ""
                                            }
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    subCategory: {
                                                        _id: e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                                subCategories.forEach(
                                                    (element) => {
                                                        if (
                                                            element._id ===
                                                            e.target.value
                                                        ) {
                                                            setLeafCategories(
                                                                element.children
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                        >
                                            <option value="">
                                                Select Sub Category
                                            </option>
                                            {subCategories?.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={cellStyle}>
                                        <select
                                            style={selectStyle}
                                            value={
                                                product.leafCategory?._id || ""
                                            }
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    leafCategory: {
                                                        _id: e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        >
                                            <option value="">
                                                Select Leaf Category
                                            </option>
                                            {leafCategories?.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={cellStyle}>
                                        <select
                                            style={selectStyle}
                                            value={product.brand?.id || ""}
                                            onChange={(e) => {
                                                const selectedBrand =
                                                    brands.find(
                                                        (b) =>
                                                            b._id ===
                                                            e.target.value
                                                    );
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    brand: {
                                                        id: e.target.value,
                                                        _id: e.target.value,
                                                        name: selectedBrand?.name,
                                                    },
                                                };
                                                setProducts(newProducts);
                                                if (selectedBrand) {
                                                    getDataSec(
                                                        `${baseURL}/brand?brandId=${e.target.value}`
                                                    ).then((res) => {
                                                        if (
                                                            res &&
                                                            res.success
                                                        ) {
                                                            setSubBrands(
                                                                res.brand
                                                                    .subBrands
                                                            );
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            <option value="">
                                                Select Brand
                                            </option>
                                            {brands?.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={cellStyle}>
                                        <select
                                            style={selectStyle}
                                            value={product.subBrand?.id || ""}
                                            onChange={(e) => {
                                                const selectedSubBrand =
                                                    subBrands.find(
                                                        (b) =>
                                                            b._id ===
                                                            e.target.value
                                                    );
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    subBrand: {
                                                        id: e.target.value,
                                                        _id: e.target.value,
                                                        name: selectedSubBrand?.name,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        >
                                            <option value="">
                                                Select Sub Brand
                                            </option>
                                            {subBrands?.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.recommendedAttribute}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    recommendedAttribute:
                                                        e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="number"
                                            value={product.shipping.weight}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    shipping: {
                                                        weight: e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.hsnCode}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    hsnCode: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.barCode}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    barCode: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="number"
                                            value={product.price}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    price: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="number"
                                            value={product.sellPrice}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    sellPrice: e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="number"
                                            value={product.minSellPrice}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    minSellPrice:
                                                        e.target.value,
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.seo?.metaTitle}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    seo: {
                                                        ...newProducts[index]
                                                            .seo,
                                                        metaTitle:
                                                            e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.seo?.metaKeywords}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    seo: {
                                                        ...newProducts[index]
                                                            .seo,
                                                        metaKeywords:
                                                            e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <input
                                            style={inputStyle}
                                            type="text"
                                            value={product.seo?.metaDescription}
                                            onChange={(e) => {
                                                const newProducts = [
                                                    ...products,
                                                ];
                                                newProducts[index] = {
                                                    ...newProducts[index],
                                                    seo: {
                                                        ...newProducts[index]
                                                            .seo,
                                                        metaDescription:
                                                            e.target.value,
                                                    },
                                                };
                                                setProducts(newProducts);
                                            }}
                                        />
                                    </td>
                                    <td style={cellStyle}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                alignItems: "stretch",
                                            }}
                                        >
                                            <button
                                                style={{
                                                    padding: "4px 8px",
                                                    backgroundColor:
                                                        product.status ===
                                                        "submitted"
                                                            ? "#4CAF50"
                                                            : "#2196F3",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: 4,
                                                    cursor: "pointer",
                                                    opacity:
                                                        product.status ===
                                                        "submitting"
                                                            ? 0.7
                                                            : 1,
                                                    width: "100%",
                                                    minWidth: "80px",
                                                }}
                                                onClick={() =>
                                                    handleSubmitRow(index)
                                                }
                                                disabled={
                                                    product.status ===
                                                    "submitting"
                                                }
                                            >
                                                {product.status === "submitting"
                                                    ? "Submitting..."
                                                    : "Submit"}
                                            </button>
                                            <button
                                                style={{
                                                    padding: "4px 8px",
                                                    backgroundColor: "#f44336",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: 4,
                                                    cursor: "pointer",
                                                    opacity:
                                                        product.status ===
                                                        "submitting"
                                                            ? 0.7
                                                            : 1,
                                                    width: "100%",
                                                    minWidth: "80px",
                                                }}
                                                onClick={() => {
                                                    const newProducts = [
                                                        ...products,
                                                    ];
                                                    newProducts.splice(
                                                        index,
                                                        1
                                                    );
                                                    setProducts(newProducts);
                                                }}
                                                disabled={
                                                    product.status ===
                                                    "submitting"
                                                }
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Floating buttons container */}
            <div
                style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "16px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    zIndex: 1000,
                    backdropFilter: "blur(5px)",
                }}
            >
                {isSubmittingAll && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            marginRight: "20px",
                        }}
                    >
                        <CircularProgress size={20} />
                        <span>
                            Processing {submissionProgress.current} of{" "}
                            {submissionProgress.total}
                        </span>
                    </div>
                )}
                <button
                    onClick={handleDownloadCSV}
                    style={{
                        padding: "8px 16px",
                        backgroundColor: "#673ab7",
                        color: "white",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        minWidth: "120px",
                    }}
                >
                    Download CSV
                </button>
                <button
                    onClick={addNewRow}
                    style={{
                        padding: "8px 16px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        minWidth: "120px",
                    }}
                >
                    Add New Row
                </button>
                <button
                    onClick={handleSubmitAll}
                    disabled={isSubmittingAll || products.length === 0}
                    style={{
                        padding: "8px 16px",
                        backgroundColor: "#2196F3",
                        color: "white",
                        border: "none",
                        borderRadius: 4,
                        cursor: isSubmittingAll ? "not-allowed" : "pointer",
                        opacity: isSubmittingAll ? 0.7 : 1,
                        minWidth: "120px",
                    }}
                >
                    {isSubmittingAll ? "Submitting..." : "Submit All"}
                </button>
                <button
                    onClick={() => navigate("/products")}
                    style={{
                        padding: "8px 16px",
                        backgroundColor: "#f44336",
                        color: "white",
                        border: "none",
                        borderRadius: 4,
                        cursor: "pointer",
                        minWidth: "120px",
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

const headerStyle = {
    padding: "12px 8px",
    textAlign: "left" as const,
    borderBottom: "2px solid #ddd",
    border: "1px solid #ddd",
    height: "50px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#f5f5f5",
};

const rowStyle = {
    borderBottom: "1px solid #ddd",
    height: "50px",
    "&:hover": {
        backgroundColor: "#f8f9fa",
    },
};

const cellStyle = {
    padding: "8px",
    verticalAlign: "middle" as const,
    border: "1px solid #ddd",
    height: "50px",
    overflow: "hidden",
};

const inputStyle = {
    width: "100%",
    padding: "4px 8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "14px",
    height: "36px",
    boxSizing: "border-box" as const,
};

const textareaStyle = {
    ...inputStyle,
    height: "36px",
    resize: "none" as const,
    overflow: "auto",
};

const selectStyle = {
    ...inputStyle,
    backgroundColor: "white",
};

const resizeHandleStyle = {
    position: "absolute" as const,
    right: 0,
    top: 0,
    height: "100%",
    width: "5px",
    background: "transparent",
    cursor: "col-resize",
    userSelect: "none" as const,
    touchAction: "none" as const,
    "&:hover": {
        background: "#ddd",
    },
};
