import React, { useEffect, useState } from "react";
import {
    getDataSec,
    postDataSec,
    putDataSec,
    putDataSec2,
} from "../../Apis/Api";
import { baseURL, imgUrl, liveURL } from "../../config/config";
import { toast } from "react-toastify";
import "./Brands.css";
import Switch from "@mui/material/Switch";
import moment from "moment";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";

const Brands = () => {
    const [brandList, setBrandList] = useState([]);
    const [originalBrands, setOriginalBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState();
    const [addNew, setAddNew] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [brandDetail, setBrandDetail] = useState();
    const [imageSrc, setImageSrc] = useState();
    const [previewSrc, setPreviewSrc] = useState();
    const [imageLongPath, setImageLongPath] = useState();
    const [renderView, setRenderView] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const permissions = JSON.parse(
            localStorage.getItem("permissions")
        )?.map((item) => item.name);
        if (permissions?.includes("brands")) {
            setRenderView(true);
        }
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "image/png") {
            setImageSrc(file); // Store the raw file (binary)
            setPreviewSrc(URL.createObjectURL(file)); // Set preview source
        } else {
            alert("Please upload a PNG image.");
        }
    };

    const refreshSelectedBrand = async (selectedBrand) => {
        getDataSec(baseURL + "/brand?brandId=" + selectedBrand._id).then(
            async (res) => {
                if (res?.success) {
                    const branddetail = res.brand;
                    if (res.brand?.childIds?.length > 0) {
                        // Fetch all child brand details
                        const childBrandDetails = await Promise.all(
                            res.brand.childIds.map((item) =>
                                getDataSec(
                                    baseURL + "/brand?brandId=" + item
                                ).then((childRes) => childRes.brand)
                            )
                        );
                        // Assign the fetched child brand details to branddetail.childIds
                        branddetail.childIds = childBrandDetails;
                    } else {
                        branddetail.childIds = [];
                    }
                    setPreviewSrc(
                        `${imgUrl}/brand/${branddetail.id}/${branddetail.image}`
                    );
                    setBrandDetail(branddetail);
                }
            }
        );
    };

    useEffect(() => {
        if (selectedBrand) {
            refreshSelectedBrand(selectedBrand);
        }
    }, [selectedBrand]);

    const getBrands = async () => {
        try {
            const result = await getDataSec(
                baseURL + "/brands?start=0&limit=100000"
            );
            console.log(result);
            if (result.brand) {
                const brands = result.brand.sort((a, b) => a.id - b.id);
                setBrandList(brands);
                setOriginalBrands(brands);
            } else {
                toast.error("Unable to fetch brands");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        }
    };

    useEffect(() => {
        getBrands();
        document.body.style.overflow = "hidden";
    }, []);

    useEffect(() => {
        if (searchQuery.length > 0) {
            const filteredBrands = originalBrands.filter((item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setBrandList(filteredBrands);
        } else {
            setBrandList(originalBrands);
        }
    }, [searchQuery]);

    const copyTagLink = (itemId) => {
        navigator.clipboard
            .writeText(`https://aapkabazar.co/tags/${itemId}`)
            .then(() => {
                toast.success("Copied to clipboard");
            });
    };

    const modifyTag = async (imagepath = null) => {
        try {
            if (addNew) {
                const data = {
                    isActive: selectedBrand.isActive,
                    tag_name: selectedBrand.name,
                };
                const result = await postDataSec(baseURL + "/addTags", data);
                if (result.success) {
                    toast.success(result.message);
                    getBrands();
                    setAddNew(false);
                    setSelectedBrand();
                    setSearchQuery("");
                } else {
                    toast.error(result.message);
                }
                return;
            }

            if (selectedBrand._id && selectedBrand._id === null) {
                toast.error("Tag Id not found");
            }
            if (!imagepath) {
                return toast.error("Something went wrong.");
            }
            const data = {
                name: selectedBrand.name,
                description: selectedBrand?.description,
                brandId: selectedBrand._id,
                isActive: selectedBrand.isActive,
                image: imagepath,
            };

            const result = await putDataSec(
                baseURL + "/brand?brandId=" + selectedBrand._id,
                data
            );
            if (result.success) {
                toast.success(result.message);
                getBrands();
                // setSelectedBrand();
                setSearchQuery("");
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log("error in updating tag", err);
            toast.error("Error in updating brands");
        }
    };

    const addNewBrand = async () => {
        try {
            if (!selectedBrand) {
                console.error("selectedBrand is null, setting default value.");
                setSelectedBrand({
                    name: "",
                    description: "",
                    image: null,
                });
                return;
            }

            const payload = {
                name: brandDetail?.name,
                image: brandDetail.image || null,
                description: brandDetail?.description,
            };

            console.log("Sending Payload:", payload);
            const response = await postDataSec(baseURL + "/brand", payload);

            if (response) {
                toast.success("Brand added successfully!");
                getBrands();
                setAddNew(false);
                setSelectedBrand(null);
                setBrandDetail(null);
                setSearchQuery("");
            } else {
                toast.error("Failed to add brand.");
            }
        } catch (error) {
            console.error("Error adding brand:", error);
            toast.error("Something went wrong while adding brand.");
        }
    };

    return !renderView ? (
        <h1 style={{ textAlign: "center", marginTop: 50 }}>
            You don't have permission to view this page
        </h1>
    ) : (
        <div style={{ display: "flex", flex: 1, padding: 20, gap: 20 }}>
            <div style={{ flex: 1 }} className="tagListContainer">
                <div
                    style={{
                        display: "flex",
                        maxHeight: 60,
                        marginBottom: 20,
                        flex: 1,
                        gap: 20,
                        justifyContent: "space-between",
                    }}
                >
                    <h3>Brands List</h3>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        placeholder="Search Brands"
                        style={{
                            // padding: 10,
                            flex: 1,
                            borderRadius: 8,
                            border: "1px solid #aaa",
                        }}
                    />
                    <button
                        onClick={() => {
                            const newBrand = {
                                name: "",
                                description: "",
                                tags: [],
                                image: null,
                                isActive: true,
                            };
                            setSelectedBrand(newBrand);
                            setBrandDetail(newBrand); // Form reset
                            setPreviewSrc(null);
                            setImageSrc(null);
                            setAddNew(true);
                        }}
                        style={{
                            border: "1px solid #eee",
                            padding: "10px 20px",
                            flex: 1,
                            marginBottom: 10,
                            backgroundColor: "#ccc",
                            borderRadius: 8,
                            alignSelf: "flex-end",
                        }}
                    >
                        Create New +
                    </button>
                </div>
                <table
                    cellPadding={10}
                    style={{ borderColor: "#fff", overflow: "scroll" }}
                    border={1}
                >
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }}>Id #</th>
                            <th style={{ textAlign: "left" }}>Image</th>
                            <th style={{ textAlign: "left" }}>Name</th>
                            <th style={{ textAlign: "left" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brandList.map((item, index) => {
                            return (
                                <tr className="" key={index}>
                                    <td>
                                        <p
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.id}
                                        </p>
                                    </td>
                                    <td>
                                        {item?.image?.length > 0 ? (
                                            <img
                                                style={{
                                                    height: 40,
                                                    width: 80,
                                                }}
                                                src={`${imgUrl}/brand/${item.id}/${item.image[0]}`}
                                            />
                                        ) : (
                                            <img
                                                style={{
                                                    height: 40,
                                                    width: 80,
                                                }}
                                                src="https://cpworldgroup.com/wp-content/uploads/2021/01/placeholder.png"
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <p
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.name}
                                        </p>
                                    </td>
                                    <td>
                                        <div style={{}}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <button
                                                    onClick={() => {
                                                        setSelectedBrand(null);
                                                        setImageSrc(null);
                                                        setBrandDetail(null);
                                                        setSelectedBrand(item);
                                                        setAddNew(false);
                                                    }}
                                                    style={{
                                                        border: "1px solid #aaa",
                                                        padding: "10px 15px",
                                                        backgroundColor: "#eee",
                                                        borderRadius: 8,
                                                        color: "#fff",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    >
                                                    <Edit color="primary" />
                                                </button>
                                                {/* <button
                                                    onClick={() => {
                                                        putDataSec(
                                                            baseURL + "/brand",
                                                            {
                                                                brandId:
                                                                    item._id,
                                                                isActive:
                                                                    item?.isActive
                                                                        ? false
                                                                        : true,
                                                            }
                                                        ).then((res) => {
                                                            if (res.success) {
                                                                toast.success(
                                                                    res.message
                                                                );
                                                                getBrands();
                                                            } else {
                                                                toast.error(
                                                                    res.message
                                                                );
                                                            }
                                                        });
                                                    }}
                                                    style={{
                                                        border: "1px solid #eee",
                                                        padding: "10px 20px",
                                                        width: 80,
                                                        marginBottom: 10,
                                                        backgroundColor:
                                                            "rgba(0,0,0,0.1)",
                                                        borderRadius: 8,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#0A3B65",
                                                        }}
                                                    >
                                                        {item.isActive
                                                            ? "Disable"
                                                            : "Enable"}
                                                    </span>
                                                </button> */}
                                                {/* For Delete Button*/}
                                                <button
                                                    onClick={async () => {
                                                        const userInput =
                                                            window.prompt(
                                                                `Type "YES" to confirm the deletion of "${item.name}".`
                                                            );
                                                        if (
                                                            userInput &&
                                                            userInput.toUpperCase() ===
                                                                "YES"
                                                        ) {
                                                            try {
                                                                const response =
                                                                    await axios.delete(
                                                                        `${baseURL}/brand/${item._id}`,
                                                                        {
                                                                            headers:
                                                                                {
                                                                                    "x-access-token":
                                                                                        localStorage.getItem(
                                                                                            "token2"
                                                                                        ),
                                                                                    "Content-Type":
                                                                                        "application/json",
                                                                                },
                                                                        }
                                                                    );

                                                                if (
                                                                    response
                                                                        .data
                                                                        .success
                                                                ) {
                                                                    toast.success(
                                                                        "Brand deleted successfully!"
                                                                    );
                                                                    getBrands(); // Refresh list
                                                                } else {
                                                                    toast.error(
                                                                        response
                                                                            .data
                                                                            .message
                                                                    );
                                                                }
                                                            } catch (error) {
                                                                console.error(
                                                                    "Error deleting brand:",
                                                                    error
                                                                );
                                                                toast.error(
                                                                    "Failed to delete brand."
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    style={{
                                                        border: "1px solid #aaa",
                                                        padding: "10px 15px",
                                                        backgroundColor: "#eee",
                                                        borderRadius: 8,
                                                        color: "#fff",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <Delete color="primary" />
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="tagEditorContainer">
                {brandDetail ? (
                    <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
                        <h3>{addNew ? "Add New Brand" : "Edit Brand"}</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Brand Name</label>
                                    </td>
                                    <td>
                                        <input
                                            value={brandDetail?.name}
                                            onChange={(e) => {
                                                setBrandDetail((prev) => ({
                                                    ...prev,
                                                    name: e.target.value,
                                                }));
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Brand Description</label>
                                    </td>
                                    <td>
                                        <input
                                            value={brandDetail.description}
                                            onChange={(e) => {
                                                setBrandDetail((prev) => ({
                                                    ...prev,
                                                    description: e.target.value,
                                                }));
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Active</label>
                                    </td>
                                    <td>
                                        <Switch
                                            disableRipple
                                            onChange={() => {
                                                setBrandDetail((prev) => {
                                                    return {
                                                        ...prev,
                                                        isActive:
                                                            !prev.isActive,
                                                    };
                                                });
                                            }}
                                            checked={brandDetail.isActive}
                                        />
                                    </td>
                                </tr>
                                {brandDetail.childIds?.length > 0 &&
                                    brandDetail.childIds.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <label>
                                                        Sub Brand ({index + 1})
                                                    </label>
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            width: "100%",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </p>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                gap: 10,
                                                            }}
                                                        >
                                                            <Switch
                                                                disableRipple
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setSelectedBrand(
                                                                        null
                                                                    );
                                                                    putDataSec(
                                                                        baseURL +
                                                                            "/brand",
                                                                        {
                                                                            brandId:
                                                                                item._id,
                                                                            isActive:
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    ).then(
                                                                        (
                                                                            res
                                                                        ) => {
                                                                            if (
                                                                                res.success
                                                                            ) {
                                                                                toast.success(
                                                                                    res.message
                                                                                );
                                                                                refreshSelectedBrand(
                                                                                    brandDetail
                                                                                );
                                                                            } else {
                                                                                toast.error(
                                                                                    res.message
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                                checked={
                                                                    item?.isActive
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                <tr>
                                    <td>Image</td>
                                    <td>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    height: 40,
                                                    gap: 20,
                                                    width: 80,
                                                }}
                                                src={previewSrc}
                                            />
                                            <input
                                                style={{ maxWidth: "50%" }}
                                                type="file"
                                                accept="image/png"
                                                onChange={handleImageUpload}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                marginTop: 10,
                                justifyContent: "space-between",
                                flexDirection: "row",
                            }}
                        >
                            {!addNew && (
                                <button
                                    className="SaveBtn"
                                    onClick={async () => {
                                        setSelectedBrand(null);
                                        putDataSec(baseURL + "/brand", {
                                            brandId: brandDetail._id,
                                            isActive: brandDetail?.isActive,
                                            name: brandDetail?.name,
                                            description:
                                                brandDetail?.description,
                                        }).then((res) => {
                                            if (res?.success) {
                                                toast.success(res.message);
                                                getBrands();
                                                setSelectedBrand(null);
                                                setBrandDetail(null);
                                            } else {
                                                toast.error(
                                                    res.message ||
                                                        "Something went wrong."
                                                );
                                            }
                                        });
                                    }}
                                >
                                    Save Info
                                </button>
                            )}

                            <button
                                style={{ backgroundColor: "red" }}
                                className="SaveBtn"
                                onClick={async () => {
                                    if (!imageSrc) {
                                        toast.error(
                                            "Please select an image first!"
                                        );
                                        return;
                                    }

                                    // Edit ke liye `liveURL`, Add New ke liye `baseURL` use ho raha hai
                                    const uploadURL = isEditing
                                        ? liveURL + "/upload"
                                        : baseURL + "/upload";

                                    const fd = new FormData();
                                    fd.append("image", imageSrc);
                                    try {
                                        const result2 = await axios.put(
                                            uploadURL,
                                            fd,
                                            {
                                                headers: {
                                                    "x-access-token":
                                                        localStorage.getItem(
                                                            "token2"
                                                        ),
                                                    "Content-Type":
                                                        "multipart/form-data",
                                                },
                                            }
                                        );
                                        if (result2 && result2.data?.success) {
                                            setImageLongPath(
                                                result2.data.path.filename
                                            );
                                            toast.success(
                                                "Image uploaded successfully"
                                            );

                                            if (isEditing) {
                                                // Edit mode: Tag modify hoga
                                                modifyTag(
                                                    result2.data.path.filename
                                                );
                                            } else {
                                                // Add new mode: Brand ka image set hoga
                                                setBrandDetail((prev) => ({
                                                    ...prev,
                                                    image: result2.data.path
                                                        .filename,
                                                }));
                                            }
                                        } else {
                                            toast.error("Image upload failed!");
                                        }
                                    } catch (error) {
                                        console.error(
                                            "Error uploading image:",
                                            error
                                        );
                                        toast.error("Something went wrong!");
                                    }
                                }}
                            >
                                Save Image
                            </button>
                            <button
                                style={{ backgroundColor: "blue" }}
                                className="SaveBtn"
                                onClick={async () => {
                                    if (addNew) {
                                        await addNewBrand();
                                    } else {
                                        const name = prompt(
                                            "Enter Sub Brand Name"
                                        );
                                        if (!name || name.length < 2) {
                                            return toast.error(
                                                "Please enter valid name"
                                            );
                                        }
                                        setSelectedBrand(null);
                                        postDataSec(baseURL + "/add/subbrand", {
                                            brandId: brandDetail._id,
                                            image: null,
                                            tags: name,
                                            parentBrandId: brandDetail._id,
                                            isActive: brandDetail?.isActive,
                                            name: name,
                                            description: name,
                                        }).then((res) => {
                                            if (res?.success) {
                                                toast.success(res.message);
                                                getBrands();
                                                setSelectedBrand(null);
                                                setBrandDetail(null);
                                            } else {
                                                toast.error(
                                                    res.message ||
                                                        "Something went wrong."
                                                );
                                            }
                                        });
                                    }
                                }}
                            >
                                {addNew
                                    ? "Create New Brand"
                                    : "Insert Sub Brand"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>
                        Click on <strong>Edit</strong> to modify the brand
                    </p>
                )}
            </div>
        </div>
    );
};

export default Brands;
