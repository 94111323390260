import axios from "axios";
import { baseURL } from "../config/config";
const token = localStorage.getItem("token2");

const getCustomersApi = async (body, page = 0, limit = 10) => {
    const url = `${baseURL}/users?page=${page}&limit=${limit}`;

    try {
        const response = await axios.post(url, body, {
            headers: {
                "x-access-token": token,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error; // Re-throwing the error for handling in the calling code
    }
};

const getCustomerDetailsApi = async (id) => {
    const url = `${baseURL}/user/details`;

    try {
        const response = await axios.get(url, {
            headers: {
                "x-access-token": token,
            },
            params: {
                _id: id,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error; // Re-throwing the error for handling in the calling code
    }
};

const getCustomerByPhoneApi = async (num) => {
    try {
        const res = await axios.get(`${baseURL}/user/details`, {
            headers: {
                "x-access-token": token,
            },
            params: {
                phoneNo: num,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

const exportCsvApi = async (body) => {
    const url = `${baseURL}/user/getCsv`;

    try {
        const response = await axios.post(url, body, {
            headers: {
                "x-access-token": token,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw error;
    }
};

export {
    getCustomersApi,
    getCustomerDetailsApi,
    getCustomerByPhoneApi,
    exportCsvApi,
};
