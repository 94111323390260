import React, { useState } from "react";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Container from "../../components/Container";
import Button from "../../components/Button";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

const GenerateRefund = () => {
    const [orderId, setOrderId] = useState("");
    const [order, setOrder] = useState(null);
    const [refundProducts, setRefundProducts] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchOrder = async () => {
        setLoading(true);
        try {
            const response = await getDataSec(
                `${baseURL}/order?orderId=${orderId}`
            );
            if (response.success) {
                setOrder(response.order);
                setRefundProducts(response.order.products);
            } else {
                toast(response.message);
            }
        } catch (err) {
            toast("Failed to fetch order. Please check the order ID.");
        } finally {
            setLoading(false);
        }
    };

    const handleProductSelection = (productId) => {
        // setSelectedProducts((prev) => ({
        //     ...prev,
        //     [productId]: !prev[productId],
        // }));
    };

    const submitRefundRequest = async () => {
        const productsToRefund = refundProducts.filter;
        if (productsToRefund.length === 0) {
            alert("Please select at least one product to refund.");
            return;
        }

        try {
            await postDataSec(`${baseURL}/v2/order/refund`, {
                orderId,
                products: productsToRefund,
            });
            alert("Refund request submitted successfully.");
        } catch (err) {
            alert("Failed to submit refund request.");
        }
    };

    return (
        <Container>
            <h1>Generate Refund</h1>
            <input
                type="text"
                placeholder="Enter Order ID"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
            />
            <Button onClick={fetchOrder} disabled={loading}>
                {loading ? "Fetching..." : "Fetch Order"}
            </Button>
            {order && (
                <div>
                    <h2>Order Details</h2>
                    <h5>{order.address.mobileNo}</h5>
                    <h5>{order.address.line1}</h5>
                    <h5>{order.address.line2}</h5>
                    <h5>{order.address.fullAddress}</h5>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {refundProducts.map((product) => (
                                <TableRow key={product.id} style={{ gap: 20 }}>
                                    <TableCell>
                                        <p>Bar Code : {product.barCode}</p>
                                        <p>Id : {product.id}</p>
                                        <p>Item Code : {product.hsnCode}</p>
                                    </TableCell>
                                    <TableCell>
                                        <img
                                            src={`https://image.aapkabazar.co/product/${product.id}/${product.images[0]}`}
                                            alt={product.name}
                                            width={50}
                                            height={50}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <p>{product.name}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>₹{product.price}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{product.quantity}</p>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            // checked={
                                            //     !!selectedProducts[product.id]
                                            // }
                                            onChange={() =>
                                                handleProductSelection(
                                                    product.id
                                                )
                                            }
                                            color="primary"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button onClick={submitRefundRequest}>
                        Submit Refund Request
                    </Button>
                </div>
            )}
        </Container>
    );
};

export default GenerateRefund;
