import dashboardSvg from "../assets/dashboard.svg";
import OfferIcon from "../assets/OfferIcon.svg";
import TagIcon from "../assets/TagIcon.svg";
import BrandsIcon from "../assets/BrandsIcon.svg";
import ChatsIcon from "../assets/ChatsIcon.svg";
import DesignIcon from "../assets/DesignIcon.svg";
import productSvg from "../assets/product.svg";
import config from "../assets/configSvg.svg";
import imageicon from "../assets/imageicon.svg";
import order from "../assets/order.svg";

export const menuDefinitions = [
    {
        name: "dashboard",
        title: "Dashboard",
        icon: dashboardSvg,
        route: "/dashboard",
    },
    {
        name: "complaints",
        title: "Complaints",
        icon: OfferIcon,
        route: "/complaints",
    },
    {
        name: "products",
        title: "Products",
        icon: productSvg,
        route: "/products",
    },
    {
        name: "apphomescreen",
        title: "App Home Screen",
        icon: DesignIcon,
        route: "/apphomescreen",
    },
    {
        name: "tags",
        title: "Tags",
        icon: TagIcon,
        route: "/tags",
    },
    {
        name: "brands",
        title: "Brands",
        icon: BrandsIcon,
        route: "/brands",
    },
    {
        name: "chatbot",
        title: "Chats",
        icon: ChatsIcon,
        route: "/chatbot",
    },
    {
        name: "config",
        title: "Config",
        icon: config,
        route: "/config",
    },
    {
        name: "orders",
        title: "Orders",
        icon: order,
        route: "/orders",
    },
    {
        name: "customImage",
        title: "Image Upload",
        icon: imageicon,
        route: "/customImage",
    },
    {
        name: "smartList",
        title: "Smart list",
        icon: imageicon,
        route: "/smartlist",
    },
    {
        name: "promocode",
        title: "Promocode",
        icon: imageicon,
        route: "/promocode",
    },
    {
        name: "seller",
        title: "Seller",
        icon: imageicon,
        route: "/seller",
    },
    {
        name: "campaign",
        title: "Campaign",
        icon: imageicon,
        route: "/campaign",
    },
    {
        name: "iam",
        title: "iam",
        icon: imageicon,
        route: "/iam",
    },
    {
        name: "servingarea",
        title: "Serving Area",
        icon: imageicon,
        route: "/servingarea",
    },
    {
        name: "ratings",
        title: "Ratings",
        icon: imageicon,
        route: "/ratings",
    },
    {
        name: "bikerental",
        title: "Bike Rental",
        icon: imageicon,
        route: "/bikerental",
    },
];
