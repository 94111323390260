import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';
import './promocode.css'
import { useNavigate, useNavigation } from 'react-router-dom';
import SinglePromocode from './SinglePromocode.tsx';
import PromoCodeOrders from './PromoCodeOrders.tsx';
import { getDataSec } from '../../Apis/Api.js';
import { checkForValidPermissions } from '../../utils/checkPermissions.js';

interface Offer {
    _id: string;
    isActive: boolean;
    name: string;
    maxDiscount: number;
    discount: number;
    promocode: string;
    useCount: number;
    minOrderPrice: number;
    startDate: string;
    expireDate: string;
    isPercent: boolean;
    offerType: string;
    offerUserType: string;
    type: string;
    description: string;
    cartDescription: string;
    created: number;
    updated: number;
    date: string;
    id: number;
    __v: number;
    user: any[]; // Define the type for the user array if possible
}

const PromoCode = () => {
    const [offers, setOffers] = useState<Offer[]>([]);
    const navigate = useNavigate();
    const [selectedPromoCode, setSelectedPromoCode] = useState<string>('');
    const [selectedPromoCode1, setSelectedPromoCode1] = useState<string>('');
    const [disableButtons, setDisableButtons] = useState<boolean | undefined>(true);

    const fetchData = async () => {
        try {
            const response = await getDataSec(`${baseURL}/offer/type?offerType=offer`);
            if (response.success) {
                setOffers(response.offer);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const { read, modify } = checkForValidPermissions("promocode");
        if (read && modify) {
            setDisableButtons(false);
        }
        if (!read && !modify) {
            navigate("/404");
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedPromoCode])

    const renderOffers = () => {
        return offers.map((offer, index) => {
            return (
                <tr
                    key={index} style={{ cursor: 'pointer', backgroundColor: index % 2 ? 'rgb(250,250,250)' : 'rgb(255,255,255)' }}>
                    <td>{index + 1}</td>
                    <td>{offer.name}</td>
                    <td>{offer.discount}</td>
                    <td>{offer.maxDiscount}</td>
                    <td>{offer.useCount}</td>
                    <td><button onClick={() => { setSelectedPromoCode(offer._id) }} style={{}}>Edit PromoCode</button>
                        <button onClick={() => { setSelectedPromoCode1(offer._id) }} style={{}}>View Orders</button>
                    </td>
                </tr>
            )
        })
    }

    const addNewPromocode = () => {
        setSelectedPromoCode('new');
    }

    return (
        <div style={{ display: 'flex', backgroundColor: 'white', borderRadius: 12, margin: 20, padding: 20, flexDirection: 'column' }}>
            {selectedPromoCode !== "" && <SinglePromocode promocodeId={selectedPromoCode} setSelectedPromoCode={setSelectedPromoCode} disabled={disableButtons} />}
            {selectedPromoCode1 !== "" && <PromoCodeOrders promocode={selectedPromoCode1} setSelectedPromoCode={setSelectedPromoCode1} />}
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Offers</h1>
                <button className='SaveBtn' style={{ height: 40 }} onClick={addNewPromocode}>+ New Promocode</button>
            </div>
            <table className='promocodeContainer'>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Name</th>
                        <th>Discount</th>
                        <th>Max Discount</th>
                        <th>use Count</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderOffers()}
                </tbody>
            </table>
        </div>
    );
};

export default PromoCode;