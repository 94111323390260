import React, { useState, useEffect } from "react";
import { baseURL } from "../../config/config";
import {
    Container,
    Typography,
    Card,
    CardContent,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    CircularProgress,
    TextField,
    Grid,
} from "@mui/material";
import { Rating } from "@mui/material";
import { getDataSec } from "../../Apis/Api";

const OrderRatingsScreen = () => {
    const [orders, setOrders] = useState([]);
    const [sortBy, setSortBy] = useState("latest");
    const [ratingFilter, setRatingFilter] = useState("");
    const [commentFilter, setCommentFilter] = useState("all");
    const now = new Date();
    const [startDate, setStartDate] = useState(
        new Date(now - 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
    );
    const [endDate, setEndDate] = useState(
        new Date(now).toISOString().split("T")[0]
    );
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const limit = 10; // Fixed limit

    useEffect(() => {
        fetchOrders();
    }, [sortBy, ratingFilter, commentFilter ,startDate, endDate, page]);

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const { orders } = await getDataSec(
                `${baseURL}/order/reviews?sortBy=${sortBy}&ratingFilter=${ratingFilter}&commentFilter=${commentFilter}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
            );
            console.log("API Repsonse", orders);
            setOrders(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
        setLoading(false);
    };


    return (
        <Container maxWidth="md" style={{ marginTop: "20px" }}>
            <Typography variant="h5" gutterBottom>
                Order Ratings
            </Typography>

            {/* Sorting & Filters */}
            <Grid container spacing={2} sx={{ backgroundColor: 'white', padding: 2, borderRadius: 2 }}>
                <Grid item xs={6} >
                    <FormControl fullWidth>
                        <InputLabel>Sort By</InputLabel>
                        <Select
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                        >
                            <MenuItem value="latest">Latest</MenuItem>
                            <MenuItem value="highest">Highest Rating</MenuItem>
                            <MenuItem value="lowest">lowest Rating</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Filter by Rating</InputLabel>
                        <Select
                            value={ratingFilter}
                            onChange={(e) => setRatingFilter(e.target.value)}
                        >
                            <MenuItem value="">All Ratings</MenuItem>
                            <MenuItem value="1">1 Star</MenuItem>
                            <MenuItem value="2">2 Stars</MenuItem>
                            <MenuItem value="3">3 Stars</MenuItem>
                            <MenuItem value="4">4 Stars</MenuItem>
                            <MenuItem value="5">5 Stars</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
                 {/* New Comment Filter */}
                 <Grid item xs={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Filter by Comments</InputLabel>
                        <Select
                            value={commentFilter}
                            onChange={(e) => setCommentFilter(e.target.value)}
                        >
                            <MenuItem value="all">All Orders</MenuItem>
                            <MenuItem value="withComments">With Comments</MenuItem>
                            <MenuItem value="withoutComments">Without Comments</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Date Filters */}
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Start Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="End Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </Grid>
            </Grid>

            {/* Orders List */}
            {loading ? (
                <CircularProgress
                    style={{ display: "block", margin: "20px auto" }}
                />
            ) : orders.length > 0 ? (
                orders.map((order) => (
                    <Card
                        key={order._id}
                        variant="outlined"
                        style={{ marginTop: "10px" }}
                    >
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Order ID:</strong> {order.id}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Rating:</strong>{" "}
                                <Rating
                                    value={order.rating}
                                    readOnly
                                    precision={0.5}
                                />
                            </Typography>
                            <Typography variant="body1">
                                <strong>Delivery Rating:</strong>{" "}
                                <Rating
                                    value={order.deliveryBoyRating}
                                    readOnly
                                    precision={0.5}
                                />
                            </Typography>
                            <Typography variant="body1">
                                <strong>Order Date:</strong>{" "}
                                {new Date(order.created).toLocaleString()}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Comments:</strong>{" "}
                                {order.deliveryBoyComments || "No comments"}
                            </Typography>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography style={{ textAlign: "center", marginTop: "20px" }}>
                    No orders found.
                </Typography>
            )}

            {/* Pagination Controls */}
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                        disabled={page === 1}
                    >
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setPage(page + 1)}
                        disabled={orders.length < limit}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>

            {/* Refresh Button */}
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ marginTop: "20px" }}
                onClick={fetchOrders}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} /> : "Refresh"}
            </Button>
        </Container>
    );
};

export default OrderRatingsScreen;
