import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    TableBody,
    Switch,
} from "@mui/material";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { blue, yellow } from "@mui/material/colors";
import { use } from "react";

const DeliveryBoyTable = () => {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        new Date().toISOString().split("T")[0]
    );
 

    useEffect(() => {
        fetchDeliveryBoys(selectedDate);
    }, [selectedDate]);

    const fetchDeliveryBoys = async (date) => {
        try {
            const response = await getDataSec(
                `${baseURL}/deliveryboy/report?startDate=${date}&endDate=${date}`
            );
            console.log("Api response is: ", response);
            setDeliveryBoys(response.data);
            console.log("Setted delivery boys is :", response.data);
        } catch (error) {
            console.error("Error fetching delivery boys:", error);
            setDeliveryBoys([]);
        }
    };

    const deductAmount = async (deliveryBoyId) => {
        try {
            const response = await postDataSec(
                `${baseURL}/deliveryboy/deductAmount`,
                { deliveryPartnerId: deliveryBoyId } // Correct parameter pass
            );
            console.log("API Response:", response);

            if (!response.data || response.data.closingBalance === undefined) {
                console.error(
                    "API response does not contain closingBalance:",
                    response.data
                );
                return;
            }

            setDeliveryBoys((prevBoys) =>
                prevBoys.map((boy) =>
                    boy._id === deliveryBoyId
                        ? {
                              ...boy,
                              currentBalance: response.data.closingBalance,
                          }
                        : boy
                )
            );

            alert("Amount deducted successfully!");
        } catch (error) {
            console.error("Error deducting amount:", error);
        }
    };

    const updateBikeRented = async (deliveryBoyId, currentStatus) => {
        try {
            const response = await postDataSec(
                `${baseURL}/deliveryboy/updateRentedBike`,
                { deliveryPartnerId: deliveryBoyId, bikeRented: !currentStatus }
            );
            console.log("Bike Rental API Response:", response);

            if (response.success) {
                setDeliveryBoys((prevBoys) =>
                    prevBoys.map((boy) =>
                        boy._id === deliveryBoyId
                            ? { ...boy, bikeRented: !currentStatus }
                            : boy
                    )
                );
                alert("Bike rental status updated successfully!");
            }
        } catch (error) {
            console.error("Error updating bike rental status:", error);
        }
    };

    const rentedBoys = deliveryBoys.filter((boy) => boy.bikeRented && boy.currentBalance >= 600);
    const notRentedBoys = deliveryBoys.filter((boy) => !boy.bikeRented && boy.currentBalance >=600);
   
    return (
        <div>
            <TextField
                label="Select Date"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                sx={{ marginBottom: 2, marginTop: 3 }}
            />


              <h3>Delivery Boys Who Have Rented a Bike</h3>
            <Table style={{ backgroundColor: "white", borderRadius: 20, }}>
                <TableHead>
                    <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Earning</TableCell>
                        <TableCell>Online Time</TableCell>
                        <TableCell>Bike Rented</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rentedBoys && rentedBoys.length > 0 ? (
                            rentedBoys.map((boy, index) => (
                                <TableRow
                                    key={boy._id || index}
                                    style={{
                                        backgroundColor:
                                            "rgba(240, 240, 240, 0.7)",
                                    }}
                                >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{boy.name || "N/A"}</TableCell>
                                <TableCell>{boy.phoneNo || "N/A"}</TableCell>
                                <TableCell>{boy.currentBalance ?? "N/A"}</TableCell>
                                <TableCell>
                                    {boy.attendance?.[0]?.onlineTime || "0"}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={boy.bikeRented}
                                        onChange={() => updateBikeRented(boy._id, boy.bikeRented)}
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>
                                <Button
                                        onClick={() => deductAmount(boy._id, false)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Deduct &#8377;120
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6} style={{ textAlign: "center" }}>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {/* Table for those who haven't rented a bike */}
            <h3>Delivery Boys Who Haven't Rented a Bike Yet</h3>
            <Table style={{ backgroundColor: "white", borderRadius: 20 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Earning</TableCell>
                        <TableCell>Online Time</TableCell>
                        <TableCell>Bike Rented</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notRentedBoys.length > 0 ? (
                        notRentedBoys.map((boy, index) => (
                            <TableRow key={boy._id || index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{boy.name || "N/A"}</TableCell>
                                <TableCell>{boy.phoneNo || "N/A"}</TableCell>
                                <TableCell>{boy.currentBalance ?? "N/A"}</TableCell>
                                <TableCell>
                                    {boy.attendance?.[0]?.onlineTime || "0"}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={boy.bikeRented}
                                        onChange={() => updateBikeRented(boy._id, boy.bikeRented)}
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => deductAmount(boy._id, false)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Deduct &#8377;120
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7} style={{ textAlign: "center" }}>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default DeliveryBoyTable;