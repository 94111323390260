import React, { useEffect, useRef, useState } from "react";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import "./Chatbot.css";
import Switch from "@mui/material/Switch";
import moment from "moment";
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Drawer,
} from "@mui/material";
import { Check, Close, MoreVert, Send, Label } from "@mui/icons-material";
import CustomerDetails from "../CustomersDetails/CustomerDetails";

const Chatbot = () => {
    const [ticketList, setTicketList] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalTickets, setTotalTickets] = useState(0);
    const [messages, setMessages] = useState([]);
    const [ticketDetails, setTicketDetails] = useState();
    const inputBoxRef = useRef(null);
    const chatboxRef = useRef(null);
    const [ticketType, setTicketType] = useState("open");
    const [customerType, setCustomerType] = useState("");
    const [renderView, setRenderView] = useState(false);
    const TextingGif = "/assets/images/texting.gif";
    const timerRef = useRef(null);
    const timer2Ref = useRef(10);
    const [countdown, setCountdown] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tagOptions, setTagOptions] = useState(["Custom"]);
    const [selectedTag, setSelectedTag] = useState("");
    const [customTag, setCustomTag] = useState("");
    const [showCustomTag, setShowCustomTag] = useState(false);
    const [chatProgressValue, setChatProgressValue] = useState("active");
    const chatProgressOptions = ["active", "in progress", "wait for reply"];
    const [selectedCustomerPhone, setSelectedCustomerPhone] = useState(null);

    const dataReslove = [
        {
            id: 1,
            showMsg: "Call not picked",
            resolution: "Call not picked",
            message:
                "We tried reaching out to you over the call but couldn't connect. Please email us at support@aapkabazar.co",
            closeTicket: false,
        },
        {
            id: 2,
            showMsg: "Chat inactive",
            resolution: "Chat inactive",
            message:
                "Due to inactivity, this chat got automatically closed. Feel free to reach out to us anytime",
            closeTicket: true,
        },
        {
            id: 3,
            showMsg: "Resolved over call",
            resolution: "Resolved over call",
            message:
                "As discussed over the call, the issue has been resolved. If you have any further queries, feel free to reach out to us",
            closeTicket: true,
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const handleSendMessage = (text) => {
        setLoading(true);
        setMessages((prev) => [
            ...prev,
            {
                message: text,
                loading: true,
                source: "server",
                date: Date.now(),
            },
        ]);
        const tempTick = ticketDetails?._id;
        postDataSec(baseURL + "/chatbot/resumeTicket", {
            ticketId: tempTick,
            message: text,
        })
            .then((res) => {
                if (res.success) {
                    setMessages(res.ticket?.chats);
                    setAnchorEl(null);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Handle chat progress change
    const handleChatProgressChange = (event) => {
        const newProgressValue = event.target.value;
        setChatProgressValue(newProgressValue);

        // Update chat progress in the database
        updateChatProgress(newProgressValue);
    };

    // Function to update chat progress in the database
    const updateChatProgress = (progressValue) => {
        if (!ticketDetails || !ticketDetails._id) {
            toast.error("Ticket details not available");
            return;
        }

        const payload = {
            ticketId: ticketDetails._id,
            chatProgress: progressValue,
        };

        postDataSec(`${baseURL}/chatbot/update-chat-progress`, payload)
            .then((res) => {
                if (res.success) {
                    toast.success("Chat progress updated successfully");
                    // Update local state
                    setTicketDetails({
                        ...ticketDetails,
                        chatProgress: progressValue,
                    });
                } else {
                    toast.error(
                        res.message || "Failed to update chat progress"
                    );
                }
            })
            .catch((err) => {
                console.error("Error updating chat progress:", err);
                toast.error("Error updating chat progress");
            });
    };

    const refreshChatbox = async (thisTicket) => {
        setMessages([]);
        setTicketDetails(null);
        // Reset tag states when a new chat is opened
        setSelectedTag("");
        setCustomTag("");
        setShowCustomTag(false);

        // Reset tag states when a new chat is opened
        setSelectedTag("");
        setCustomTag("");
        setShowCustomTag(false);

        getDataSec(`${baseURL}/chatbot/viewTicket?ticketId=${thisTicket._id}`)
            .then((res) => {
                setTicketDetails({
                    id: res.tickets?.id,
                    status: res.tickets?.ticketStatus,
                    _id: res.tickets?._id,
                    resolution: res.tickets?.resolution,
                    ticketTag: res.tickets?.ticketTag || "",
                    chatProgress: res.tickets?.chatProgress || "active",
                });
                setMessages(res.tickets?.chats);
                setChatProgressValue(res.tickets?.chatProgress || "active");

                fetchTagOptions(thisTicket._id);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchTagOptions = (ticketId) => {
        getDataSec(
            `${baseURL}/chatbot/ticket-with-tag-options?ticketId=${ticketId}`
        )
            .then((res) => {
                if (res.success && res.tagOptions) {
                    setTagOptions(res.tagOptions);

                    if (res.ticket?.ticketTag) {
                        if (res.tagOptions.includes(res.ticket.ticketTag)) {
                            setSelectedTag(res.ticket.ticketTag);
                            setShowCustomTag(false);
                        } else {
                            setSelectedTag("Custom");
                            setCustomTag(res.ticket.ticketTag);
                            setShowCustomTag(true);
                        }
                    }
                }
            })
            .catch((err) => {
                console.log("Error fetching tag options:", err);
                setTagOptions(["Custom"]);
            });
    };

    // Handle tag selection
    const handleTagChange = (event) => {
        const value = event.target.value;
        setSelectedTag(value);

        if (value === "Custom") {
            setShowCustomTag(true);
        } else {
            setShowCustomTag(false);
            // Update tag in database
            updateTicketTag(value);
        }
    };

    // Handle custom tag input
    const handleCustomTagChange = (event) => {
        setCustomTag(event.target.value);
    };

    // Handle custom tag submit
    const handleCustomTagSubmit = () => {
        if (customTag.trim()) {
            updateTicketTag(customTag);
            setShowCustomTag(false);
        } else {
            toast.error("Custom tag cannot be empty");
        }
    };

    const updateTicketTag = (tagValue) => {
        if (!ticketDetails || !ticketDetails._id) {
            toast.error("Ticket details not available");
            return;
        }

        const payload = {
            ticketId: ticketDetails._id,
            tagType: selectedTag === "Custom" ? "Custom" : tagValue,
            customTagText: selectedTag === "Custom" ? customTag : "",
        };

        console.log("Sending payload:", JSON.stringify(payload));

        // Make the API call
        postDataSec(`${baseURL}/chatbot/ticket-tag`, payload)
            .then((res) => {
                if (res.success) {
                    console.log("Tag updated successfully:", res);
                    toast.success("Tag updated successfully");
                    // Update local state
                    setTicketDetails({
                        ...ticketDetails,
                        ticketTag:
                            selectedTag === "Custom" ? customTag : tagValue,
                    });

                    fetchTagOptions(ticketDetails._id);
                } else {
                    console.error("Failed to update tag:", res);
                    toast.error(res.message || "Failed to update tag");
                }
            })
            .catch((err) => {
                console.error("Unexpected error updating tag:", err);
                toast.error("Error updating tag");
            });
    };

    const getTicketList = async () => {
        try {
            setTotalTickets(0);
            const result = await getDataSec(
                baseURL +
                    "/chatbot/listAll?limit=10&page=" +
                    page +
                    "&ticketType=" +
                    ticketType +
                    "&customerType=" +
                    customerType
            );
            if (result.success) {
                setTicketList(result.tickets);
                setTotalTickets(result.totalTickets);
                setTotalPages(result.totalPages);
            } else {
                toast.error("Unable to fetch brands");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        }
    };

    const handleSearch = async () => {
        getDataSec(`${baseURL}/chatbot/viewTicket?ticketId=${searchQuery}`)
            .then((res) => {
                if (res.success) {
                    setTicketDetails({
                        id: res.tickets?.id,
                        status: res.tickets?.ticketStatus,
                        _id: res.tickets?._id,
                        resolution: res.tickets?.resolution,
                        ticketTag: res.tickets?.ticketTag || "",
                        chatProgress: res.tickets?.chatProgress || "active",
                        ticketTag: res.tickets?.ticketTag || "",
                        chatProgress: res.tickets?.chatProgress || "active",
                    });
                    setMessages(res.tickets?.chats);
                    setChatProgressValue(res.tickets?.chatProgress || "active");

                    fetchTagOptions(res.tickets?._id);
                } else {
                    toast.error("Ticket not found");
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => {
                setSearchQuery("");
            });
    };

    const handleOpenNew = async () => {
        const phone = prompt("Enter phone number of user");
        if (phone && phone.length === 10) {
            postDataSec(baseURL + "/chatbot/openNew", {
                phoneNo: phone,
            })
                .then((res) => {
                    if (res.success) {
                        getTicketList().then(() => {
                            setSelectedTicket(res.ticket);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleResolve = async () => {
        let message = prompt("Enter message to user");
        if (!message) toast.error("Please enter a valid message");
        else
            postDataSec(baseURL + "/chatbot/resolveTicket", {
                ticketId: ticketDetails?._id,
                message,
            })
                .then((res) => {
                    if (res.success) {
                        toast.success("Ticket resolved.");
                        setSelectedTicket(null);
                        getTicketList();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    };

    const handleResolution = async (message) => {
        postDataSec(baseURL + "/chatbot/resolveTicket", {
            ticketId: ticketDetails?._id,
            message,
        })
            .then((res) => {
                if (res.success) {
                    toast.success("Ticket resolved.");
                    setSelectedTicket(null);
                    getTicketList();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleResolutionQuery = (pop) => {
        handleSendMessage(pop?.message);
        if (pop?.closeTicket) handleResolution(pop?.resolution);
        else setSelectedTicket(null);
    };

    useEffect(() => {
        if (selectedTicket) {
            refreshChatbox(selectedTicket);
        }
    }, [selectedTicket]);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (countdown === 0) {
            getTicketList();
            setCountdown(10);
        } else {
            timerRef.current = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
    }, [countdown]);

    useEffect(() => {
        getTicketList();
        document.body.style.overflow = "hidden";
        const permissions = JSON.parse(localStorage.getItem("permissions")).map(
            (item) => item.name
        );
        if (permissions.includes("chatbot")) {
            setRenderView(true);
        }

        // Fetch global tag options on load
        getDataSec(`${baseURL}/chatbot/ticket-tags`)
            .then((res) => {
                if (res.success && res.tagOptions) {
                    setTagOptions(res.tagOptions);
                }
            })
            .catch((err) => {
                console.log("Error fetching global tags:", err);
                setTagOptions(["Custom"]); // Only set "Custom" as default
            });
    }, [page, ticketType, customerType]);

    const handlePhoneClick = (phoneNo) => {
        setSelectedCustomerPhone(phoneNo);
    };

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ color: "text.secondary" }}
                    >
                        {`${Math.round(props.value / 10)}s`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    return !renderView ? (
        <div className="empty-chat-state">
            <h1>Chatbot Disabled for this account</h1>
        </div>
    ) : (
        <>
            <div className="chatbot-container">
                {/* Left side - Ticket list */}
                <div className="tagListContainer">
                    <div className="ticket-controls">
                        <input
                            type="text"
                            className="search-input"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            placeholder="Search Ticket ID"
                        />

                        <CircularProgressWithLabel
                            variant="determinate"
                            value={100 - (countdown / 10) * 100}
                        />
                        <TablePagination
                            labelRowsPerPage=""
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalTickets}
                            rowsPerPage={10}
                            page={page - 1}
                            onPageChange={(event, newPage) => {
                                setPage(newPage + 1);
                            }}
                        />

                        <div className="filter-container">
                            <select
                                className="select-filter"
                                value={ticketType}
                                onChange={(e) => setTicketType(e.target.value)}
                            >
                                <option value="all">All Tickets</option>
                                <option value="open">Open Tickets</option>
                                <option value="closed">Closed Tickets</option>
                                <option value="resolved">
                                    Resolved Tickets
                                </option>
                            </select>

                            <select
                                className="select-filter"
                                value={customerType}
                                onChange={(e) =>
                                    setCustomerType(e.target.value)
                                }
                            >
                                <option value="all">All Customers</option>
                                <option value="online">Online Customers</option>
                                <option value="offline">
                                    Offline Customers
                                </option>
                            </select>
                        </div>
                    </div>

                    {loading ? (
                        <div className="loading-indicator">
                            <CircularProgress size={48} color="info" />
                        </div>
                    ) : (
                        <div className="ticket-table-container">
                            <Table className="ticket-table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Chat-Progress</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Tag</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ticketList.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell
                                                sx={{
                                                    textTransform: "capitalize",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        textDecoration:
                                                            "underline",
                                                        color: "primary.main",
                                                    },
                                                }}
                                                onClick={(e) =>
                                                    handlePhoneClick(
                                                        item.user?.phoneNo
                                                    )
                                                }
                                            >
                                                {item.user?.phoneNo}
                                                {item.user?.name && (
                                                    <p>{item.user.name}</p>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textTransform: "capitalize",
                                                    color:
                                                        item.ticketStatus ===
                                                        "resolved"
                                                            ? "green"
                                                            : "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {item.ticketStatus}
                                            </TableCell>
                                            <TableCell>
                                                {item.isOfflineCustomer
                                                    ? "Walk-In"
                                                    : "Online"}
                                            </TableCell>
                                            <TableCell>
                                                {item.chatProgress}
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <p>
                                                        {moment(
                                                            item.createdAt
                                                        ).format("DD MMM")}
                                                    </p>
                                                    <p>
                                                        {moment(
                                                            item.createdAt
                                                        ).format("hh:mm A")}
                                                    </p>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {item.ticketTag && (
                                                    <div className="ticket-tag-badge">
                                                        {item.ticketTag}
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedTicket(null);
                                                        setSelectedTicket(item);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    Chat
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>
                <div className="tagEditorContainer" style={{ width: "20%" }}>
                    {selectedTicket ? (
                        <>
                            {/* Chat header with ticket info */}
                            <div className="chat-header">
                                <div className="chat-header-item">
                                    <span>#{ticketDetails?.id}</span>
                                    <span
                                        style={{
                                            textTransform: "capitalize",
                                            color:
                                                ticketDetails?.status ===
                                                "resolved"
                                                    ? "green"
                                                    : "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {ticketDetails?.status}
                                    </span>
                                </div>

                                {/* Chat Progress selection */}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 4,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 8,
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <label style={{ fontWeight: "bold" }}>
                                            Chat Progress:
                                        </label>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            style={{ minWidth: 150 }}
                                        >
                                            <Select
                                                value={chatProgressValue}
                                                onChange={
                                                    handleChatProgressChange
                                                }
                                                displayEmpty
                                            >
                                                {chatProgressOptions.map(
                                                    (option, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {/* Tag selection */}
                                    <div
                                        style={{
                                            padding: 4,
                                            display: "flex",
                                            alignItems: "center",
                                            justifySelf: "flex-end",
                                            alignSelf: "flex-end",
                                            gap: 8,
                                        }}
                                    >
                                        <label style={{ fontWeight: "bold" }}>
                                            Tag:
                                        </label>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            style={{ minWidth: 150 }}
                                        >
                                            <Select
                                                value={selectedTag}
                                                onChange={handleTagChange}
                                                displayEmpty
                                                inputProps={{
                                                    "aria-label": "Tag",
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select Tag</em>
                                                </MenuItem>
                                                {tagOptions.map(
                                                    (tag, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={tag}
                                                        >
                                                            {tag}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>

                                        {showCustomTag && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 8,
                                                    zIndex: 9999,
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    padding: "8px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    marginTop: "95px",
                                                    right: "2%",
                                                }}
                                            >
                                                <TextField
                                                    size="small"
                                                    placeholder="Enter custom tag"
                                                    value={customTag}
                                                    onChange={
                                                        handleCustomTagChange
                                                    }
                                                    variant="outlined"
                                                    style={{ width: "150px" }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    onClick={
                                                        handleCustomTagSubmit
                                                    }
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="error"
                                                    onClick={() =>
                                                        setShowCustomTag(false)
                                                    }
                                                    style={{
                                                        marginLeft: "4px",
                                                    }}
                                                >
                                                    <Close fontSize="small" />
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Action buttons */}
                                <div className="chat-header-item">
                                    {ticketDetails?.status !== "resolved" ? (
                                        <Button
                                            endIcon={<Check color="primary" />}
                                            onClick={handleResolve}
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                        >
                                            Mark as Solved
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="text"
                                            onClick={() =>
                                                alert(ticketDetails?.resolution)
                                            }
                                        >
                                            {ticketDetails?.resolution?.substring(
                                                0,
                                                20
                                            )}
                                            {ticketDetails?.resolution?.length >
                                            20
                                                ? "..."
                                                : ""}
                                        </Button>
                                    )}

                                    <Button
                                        endIcon={<Close color="primary" />}
                                        onClick={() => setSelectedTicket(null)}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: 8 }}
                                    >
                                        Close
                                    </Button>

                                    {ticketDetails?.status?.toLowerCase() !==
                                        "resolved" && (
                                        <div style={{ position: "relative" }}>
                                            <MoreVert
                                                color="primary"
                                                onClick={handleClick}
                                                style={{
                                                    cursor: "pointer",
                                                    marginLeft: 8,
                                                }}
                                            />

                                            <Popper
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                            >
                                                <Box
                                                    sx={{
                                                        cursor: "pointer",
                                                        bgcolor:
                                                            "background.paper",
                                                        boxShadow: 3,
                                                        borderRadius: 1,
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    {dataReslove.map((pop) => (
                                                        <Typography
                                                            sx={{
                                                                p: 2,
                                                                "&:hover": {
                                                                    bgcolor:
                                                                        "rgba(0,0,0,0.05)",
                                                                },
                                                            }}
                                                            key={pop?.id}
                                                            onClick={() =>
                                                                handleResolutionQuery(
                                                                    pop
                                                                )
                                                            }
                                                        >
                                                            {pop?.showMsg}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </Popper>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Chat messages */}
                            <div className="chat-messages" ref={chatboxRef}>
                                {messages?.map((message, index) => (
                                    <div
                                        key={message._id || index}
                                        className={`message-container ${message.source}`}
                                    >
                                        <div
                                            className={`message-bubble ${message.source}`}
                                        >
                                            {message?.message}

                                            {message?.images && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 8,
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    {message.images.map(
                                                        (btnText, idx) => (
                                                            <button
                                                                key={idx}
                                                                onClick={() =>
                                                                    window.open(
                                                                        `https://devfrontendapi.aapkabazar.co/api/public/chatbot/${ticketDetails?._id}/${btnText}`
                                                                    )
                                                                }
                                                                style={{
                                                                    padding:
                                                                        "4px 8px",
                                                                    backgroundColor:
                                                                        "#f0f0f0",
                                                                    border: "1px solid #ddd",
                                                                    borderRadius:
                                                                        "4px",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {btnText}
                                                            </button>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {message?.buttons && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 4,
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    {message.buttons.map(
                                                        (btnText, idx) => (
                                                            <p
                                                                key={idx}
                                                                style={{
                                                                    color: "#3B82F6",
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontStyle:
                                                                        "italic",
                                                                }}
                                                            >
                                                                {" > "}
                                                                {btnText?.title}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {message?.loading && (
                                                <CircularProgress
                                                    size={16}
                                                    style={{
                                                        color: "#aaa",
                                                        marginLeft: 8,
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {!message?.loading && (
                                            <span
                                                className="message-timestamp"
                                                onClick={() => {
                                                    navigator.clipboard
                                                        .writeText(
                                                            message.message
                                                        )
                                                        .then(() =>
                                                            toast.success(
                                                                "Message copied to clipboard"
                                                            )
                                                        )
                                                        .catch(() =>
                                                            toast.error(
                                                                "Failed to copy message"
                                                            )
                                                        );
                                                }}
                                            >
                                                {moment(message.date).format(
                                                    "ddd DD MMM hh:mm A"
                                                )}{" "}
                                                📌
                                            </span>
                                        )}
                                    </div>
                                ))}

                                {loading && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            margin: "8px 0",
                                            alignSelf: "flex-end",
                                        }}
                                    >
                                        <img
                                            height={50}
                                            width={50}
                                            src="/assets/images/texting.gif"
                                            alt="Typing..."
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Input area */}
                            {messages &&
                                messages?.length >= 1 &&
                                ticketDetails?.status !== "resolved" &&
                                !loading && (
                                    <div className="message-input-container">
                                        <div className="message-input">
                                            <input
                                                ref={inputBoxRef}
                                                type="text"
                                                placeholder="Type a message"
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key === "Enter" &&
                                                        e.target.value.trim()
                                                    ) {
                                                        handleSendMessage(
                                                            e.target.value.trim()
                                                        );
                                                        e.target.value = "";
                                                    }
                                                }}
                                            />
                                            <div
                                                className="send-button"
                                                onClick={() => {
                                                    if (
                                                        inputBoxRef.current.value.trim() &&
                                                        !loading
                                                    ) {
                                                        handleSendMessage(
                                                            inputBoxRef.current.value.trim()
                                                        );
                                                        inputBoxRef.current.value =
                                                            "";
                                                    }
                                                }}
                                            >
                                                <Send />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    ) : (
                        <div className="empty-chat-state">
                            <h3>No chat selected</h3>
                            <p>
                                Click on <strong>Chat button</strong> to open a
                                specific chat
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Drawer
                anchor="right"
                open={selectedCustomerPhone}
                onClose={() => setSelectedCustomerPhone(null)}
                style={{
                    flexShrink: 0,
                    maxWidth: "400px",
                    zIndex: 99999999,
                    borderRadius: "8px",
                }}
            >
                {selectedCustomerPhone ? (
                    <div
                        style={{
                            maxWidth: "1000px",
                            overflow: "scroll",
                            backgroundColor: "#F3F9F7",
                        }}
                    >
                        <CustomerDetails
                            phoneNo={selectedCustomerPhone}
                            onClose={() => setSelectedCustomerPhone(null)}
                            showBackButton={false}
                        />
                    </div>
                ) : (
                    <div className="customer-details-placeholder">
                        <p>Click on a phone number to view customer details</p>
                    </div>
                )}
            </Drawer>
        </>
    );
};

export default Chatbot;
