import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataSec, postDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

const CampaignView = () => {
    const location = useLocation();
    const arr = location.pathname.split("/");
    const campaignId = arr[arr.length - 1];
    const [campaign, setCampaign] = useState(null);
    const navigate = useNavigate();
    const [testNumber, setTestNumber] = useState("");

    const getCampaignDetail = async () => {
        try {
            setCampaign(null);
            const result = await getDataSec(
                `${baseURL}/campaign?id=${campaignId}`
            );
            if (result.success) {
                setCampaign(result.campaign);
            }
        } catch (error) {
            console.error("Error fetching campaign details:", error);
        }
    };

    const sendTestMessage = async () => {
        try {
            if (testNumber.length !== 10) {
                toast.error("Please enter a valid number");
                return;
            }
            const result = await postDataSec(`${baseURL}/campaign/test`, {
                id: campaign._id,
                phoneNo: testNumber,
            });
            if (result.success) {
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("Error sending test message");
        }
    };

    useEffect(() => {
        getCampaignDetail();
    }, [campaignId]);

    async function completeCampaign() {
        try {
            const result = postDataSec(`${baseURL}/campaign/complete`, {
                id: campaign._id,
            });
            if (result.success) {
                getCampaignDetail();
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("error in complete campaign fn");
            console.error("Error completing campaign:", error);
        }
    }

    async function activateCampaign() {
        try {
            console.log(campaign);
            const result = await postDataSec(`${baseURL}/campaign/start`, {
                id: campaign._id,
            });
            if (result.success) {
                getCampaignDetail();
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("error in starting campaign fn");
            console.error("Error starting campaign:", error);
        }
    }

    async function backClick() {
        navigate(-1);
    }

    if (!campaign) {
        return <div>Loading...</div>;
    }

    return (
        <div
            style={{
                padding: "20px",
                maxWidth: "95%",
                margin: "auto",
                border: "1px solid #eee",
                borderRadius: "8px",
                backgroundColor: "#fff",
                marginTop: 20,
            }}
        >
            <Button onClick={backClick}>Back</Button>
            <h1 style={{ textAlign: "center" }}>{campaign.name}</h1>
            <p>
                <strong>Description:</strong> {campaign.description}
            </p>
            <p>
                <strong>Amount:</strong> Rs{campaign.amount}
            </p>
            <p>
                <strong>Status:</strong> {campaign.status}
                <br />
                {campaign.status === "pending" && (
                    <Button variant="outlined" onClick={activateCampaign}>
                        Activate
                    </Button>
                )}
                {campaign.status === "active" && (
                    <Button variant="outlined" onClick={completeCampaign}>
                        Complete Campaign
                    </Button>
                )}
            </p>
            <p>
                <strong>Type:</strong> {campaign.type}
            </p>
            <h3>Sub Campaigns</h3>
            <input
                onChange={(e) => {
                    setTestNumber(e.target.value);
                }}
                placeholder="number"
            />
            <Button onClick={sendTestMessage}>Send Test Message</Button>
            {campaign.subCampaigns.length > 0 ? (
                <div>
                    {campaign.subCampaigns.map((sub, index) => (
                        <div key={index}>
                            <p>{sub.name}</p>
                            <Button
                                onClick={() => navigate(`/campaign/${sub.id}`)}
                            >
                                View
                            </Button>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No sub campaigns available.</p>
            )}
            <h3>Converted Users</h3>
            {campaign.users.length > 0 ? (
                <ul>
                    {campaign.users.map((user, index) => (
                        <li key={index}>{user}</li>
                    ))}
                </ul>
            ) : (
                <p>No users converted.</p>
            )}
            <h3>Total target customers</h3>
            <p>{campaign.totalTargetCustomers}</p>
            <h3>Sample Target Customers</h3>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {campaign.targetCustomers.map((number, idx) => (
                    <span key={idx}>{number}</span>
                ))}
            </div>
        </div>
    );
};

export default CampaignView;
