import {
    Button,
    FormControl,
    FormGroup,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";

const CreateCampaignModal = ({ setCreateCampaignModalOpen, campaignList }) => {
    const [formDataState, setFormData] = useState({
        name: "",
        description: "",
        type: "wallet",
        amount: "",
        mainCampaignId: "",
        wp_imgUrl: "",
        templateName: "",
        wp_variables: "",
    });

    const fileInputRef = useRef(null);

    const createCampaign = async () => {
        if (
            !formDataState.name ||
            !formDataState.description ||
            !formDataState.type
        ) {
            alert("Please fill all the fields");
            return;
        }
        if (formDataState.type === "wallet" && !formDataState.amount) {
            alert("Please enter an amount");
            return;
        }

        if (formDataState.type === "message" && !formDataState.templateName) {
            alert("Please fill all the fields");
            return;
        }

        const file = fileInputRef.current?.files[0];
        if (!file && !formDataState.mainCampaignId) {
            alert("Please upload a CSV file");
            return;
        }

        const formData = new FormData();
        formData.append("name", formDataState.name);
        formData.append("description", formDataState.description);
        formData.append("type", formDataState.type);
        formData.append("customerCsvFile", file);

        formData.append("mainCampaignId", formDataState.mainCampaignId);

        if (formDataState.type === "wallet") {
            formData.append("amount", formDataState.amount);
        } else if (formDataState.type === "message") {
            formData.append("templateName", formDataState.templateName);
            formData.append("wp_imgUrl", formDataState.imgUrl);
            formData.append(
                "wp_variables",
                formDataState.wp_variables.split(",")
            );
        }

        try {
            const response = await fetch(`${baseURL}/campaign/create`, {
                method: "POST",
                body: formData,
                headers: {
                    "x-access-token": localStorage.getItem("token2"),
                },
            });
            const data = await response.json();
            if (data.success) {
                toast.success("Campaign created successfully");
                setCreateCampaignModalOpen(false);
            } else {
                toast.error("Failed to create campaign");
            }
        } catch (error) {
            console.error("Error creating campaign:", error);
            alert("An error occurred while creating the campaign");
        }
    };

    const changeFormData = (e) => {
        setFormData({
            ...formDataState,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div
            onClick={() => setCreateCampaignModalOpen(false)}
            style={{
                position: "fixed",
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 100000,
                width: "100vw",
                height: "100%",
                top: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    padding: "20px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    margin: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "400px",
                }}
            >
                <h1>Create Campaign</h1>
                <FormGroup style={{ gap: 20 }}>
                    <FormControl>
                        <InputLabel>Campaign Name</InputLabel>
                        <Input onChange={changeFormData} name="name" />
                    </FormControl>
                    <FormControl>
                        <InputLabel>Description</InputLabel>
                        <Input onChange={changeFormData} name="description" />
                    </FormControl>
                    <InputLabel>Type</InputLabel>
                    <select
                        onChange={(e) =>
                            setFormData({
                                ...formDataState,
                                type: e.target.value,
                            })
                        }
                        style={{ minHeight: 30 }}
                        value={formDataState.type}
                    >
                        <option value="wallet">Wallet</option>
                        <option value="message">Message</option>
                    </select>
                    {formDataState.type === "wallet" ? (
                        <>
                            <FormControl>
                                <InputLabel>Amount</InputLabel>
                                <Input
                                    onChange={changeFormData}
                                    name="amount"
                                />
                            </FormControl>
                        </>
                    ) : (
                        <>
                            <FormControl>
                                <label>Parent campaign</label>
                                <select
                                    onChange={(e) =>
                                        setFormData({
                                            ...formDataState,
                                            mainCampaignId: e.target.value,
                                        })
                                    }
                                    style={{ minHeight: 30 }}
                                    value={formDataState.mainCampaignId}
                                >
                                    <option value={""}>none</option>
                                    {campaignList?.map((campaign, index) => (
                                        <option
                                            key={index}
                                            value={campaign._id}
                                        >
                                            {campaign.name}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Template Name</InputLabel>
                                <Input
                                    onChange={changeFormData}
                                    name="templateName"
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel>Template Image url</InputLabel>
                                <Input
                                    onChange={changeFormData}
                                    name="imgUrl"
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel>
                                    Variables (comma separated)
                                </InputLabel>
                                <Input
                                    onChange={changeFormData}
                                    name="wp_variables"
                                />
                            </FormControl>
                        </>
                    )}
                    {!formDataState.mainCampaignId && (
                        <FormGroup>
                            <InputLabel>
                                Upload CSV with column "phoneNo"
                            </InputLabel>
                            <Input type="file" inputRef={fileInputRef} />
                        </FormGroup>
                    )}
                    <Button variant="contained" onClick={createCampaign}>
                        Create Campaign
                    </Button>
                </FormGroup>
            </div>
        </div>
    );
};

export default CreateCampaignModal;
