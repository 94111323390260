import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateCampaignModal from "./CreateCampaignModal";
import { getDataSec } from "../../Apis/Api";
import { baseURL } from "../../config/config";
import { useNavigate } from "react-router-dom";

const Campaign = () => {
    const navigate = useNavigate();
    const [createCampaignModalOpen, setCreateCampaignModalOpen] =
        useState(false);

    const [campaignList, setCampaignList] = useState([]);

    const getCampaigns = async () => {
        try {
            const result = await getDataSec(`${baseURL}/campaign/list`);
            if (result.success) {
                setCampaignList(result.campaigns);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getCampaigns();
    }, []);

    return (
        <div>
            {createCampaignModalOpen && (
                <CreateCampaignModal
                    setCreateCampaignModalOpen={setCreateCampaignModalOpen}
                    campaignList={campaignList}
                />
            )}
            <Button
                onClick={() => {
                    setCreateCampaignModalOpen(true);
                }}
            >
                Create Campaign
            </Button>
            <div
                style={{
                    margin: "20px",
                    padding: 20,
                    backgroundColor: "white",
                }}
            >
                <h1>Campaigns</h1>
                {campaignList.length ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {/* Table Head */}
                        <div
                            style={{
                                display: "flex",
                                gap: 10,
                                alignItems: "center",
                                fontWeight: "bold",
                                padding: "10px",
                                borderBottom: "2px solid #ddd",
                            }}
                        >
                            <div style={{ flex: 1 }}>Name</div>
                            <div style={{ flex: 2 }}>Description</div>
                            <div style={{ flex: 1 }}>Type</div>
                            <div style={{ flex: 1 }}>Actions</div>
                        </div>
                        {/* Table Body */}
                        {campaignList.map((campaign) => (
                            <div
                                key={campaign.id}
                                style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    padding: "10px",
                                    borderBottom: "1px solid #eee",
                                }}
                            >
                                <div style={{ flex: 1 }}>{campaign.name}</div>
                                <div style={{ flex: 2 }}>
                                    {campaign.description}
                                </div>
                                <div style={{ flex: 1 }}>{campaign.type}</div>
                                <div>
                                    <Button
                                        onClick={() => {
                                            navigate(
                                                `/campaign/${campaign.id}`
                                            );
                                        }}
                                    >
                                        View
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No campaigns found</p>
                )}
            </div>
        </div>
    );
};

export default Campaign;
