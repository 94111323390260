import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../assets/logo.jpg";
import UserSvg from "../../assets/avatar.svg";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../screens/Dashboard/Dashboard";
import IAM from "../../screens/IAM/IAM";
import Page404 from "../../screens/404/404";
import AppHomeScreen from "../../screens/AppHomeScreen/AppHomeScreen.tsx";
import Category from "../../screens/Category/Category.tsx";
import PromoCode from "../../screens/Promocode/PromoCode.tsx";
import Products from "../../screens/Products/Products.jsx";
import { SingleProductPage } from "../../screens/Products/SingleProductPage.tsx";
import Seller from "../../screens/Sellers/Seller.tsx";
import SinglePromocode from "../../screens/Promocode/SinglePromocode.tsx";
import Tags from "../../screens/Tags/Tags.tsx";
import Orders from "../../screens/Orders/Orders.js";
import Brands from "../../screens/Brands/Brands.js";
import Complaints from "../../screens/Complaints/Complaints.js";
import ComplaintDetail from "../../screens/Complaints/ComplaintDetail.js";
import ServingArea from "../../screens/ServingArea/ServingArea.tsx";
import { toast } from "react-toastify";
import { SingleProductNewPage } from "../../screens/Products/SingleProductNewPage.tsx";
import { SingleProductNewDescription } from "../../screens/Products/SingleProductNewDescription.tsx";
import { SingleProductNewPageTwo } from "../../screens/Products/SingleProductNewPageTwo.tsx";
import WithdrawalRequest from "../../screens/WithdrawalRequest/WithdrawalRequest.js";
import Chatbot from "../../screens/Chatbot/Chatbot.js";
import ConfigPage from "../../screens/config/config.js";
import Campaign from "../../screens/Campaigns/Campaign.jsx";
import CampaignView from "../../screens/Campaigns/CampaignView.jsx";
import OrderDetails from "../../screens/OrderDetails/OrderDetails.js";
import ImageUpload from "../../screens/ImageUpload/ImageUpload.tsx";
import SmartList from "../../screens/SmartList/smartlist.js";
import { menuDefinitions } from "../../utils/screensOptions.js";
import { checkForValidPermissions } from "../../utils/checkPermissions.js";
import { getDataSec } from "../../Apis/Api.js";
import { baseURL } from "../../config/config.js";
import OrderRatingsScreen from "../../screens/Reviews/Review.js";
import GenerateRefund from "../../screens/Refund/GenerateRefund.js";
import CustomerDetails from "../../screens/CustomersDetails/CustomerDetails.js";
import DeliveryBoyTable from "../../screens/DeliveryBoyLogs/deliveryBoyTable.js";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const userPermissions = JSON.parse(localStorage.getItem("permissions"));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    const location = useLocation();
    const [selectedContent, setSelectedContent] = useState(location.pathname);
    const [menu, setMenu] = useState([]);

    const setMenuItem = () => {
        if (!userPermissions) {
            toast.error("No Permissions found.");
            return;
        }

        const menuItems = menuDefinitions.filter(
            ({ name }) => checkForValidPermissions(name).read
        );

        setMenu(menuItems);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await getDataSec(`${baseURL}/admin`);
                if (response.success) {
                    console.log(localStorage.getItem("permissions"));
                    console.log(JSON.stringify(response.user[0].permissions));
                    if (
                        localStorage.getItem("permissions") !==
                        JSON.stringify(response.user[0].permissions)
                    ) {
                        localStorage.setItem(
                            "permissions",
                            JSON.stringify(response.user[0].permissions)
                        );
                        window.location.reload();
                    }
                }
            } catch (error) {
                toast.error("Error while fetching permissions.");
            }
        })();
    }, []);

    useEffect(() => {
        const { read } = checkForValidPermissions(
            location.pathname.split("/")[1]
        );
        if (!read && location.pathname.split("/")[1] !== "") {
            navigate("/404");
        }
        setMenuItem();
        document.body.style.overflow = "scroll";
    }, [location.pathname]);
    const navigate = useNavigate();
    const renderContent = () => {
        return (
            <Routes>
                <Route path="/" element={<></>} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/iam" element={<IAM />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="/apphomescreen" element={<AppHomeScreen />} />
                <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/products" element={<Products />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orderdetails" element={<OrderDetails />} />
                <Route path="/customImage" element={<ImageUpload />} />
                <Route path="/product" element={<SingleProductPage />} />
                <Route
                    path="/editProduct/:id/:catId"
                    element={<SingleProductNewPage />}
                />
                <Route
                    path="/newDescription/:id/:catId"
                    element={<SingleProductNewDescription />}
                />
                <Route
                    path="/addProduct"
                    element={<SingleProductNewPageTwo />}
                />
                <Route path="/category" element={<Category />} />
                <Route path="/servingarea" element={<ServingArea />} />
                <Route path="/complaint/:id" element={<ComplaintDetail />} />
                <Route path="/complaints" element={<Complaints />} />
                <Route path="/seller" element={<Seller />} />
                <Route path="/config" element={<ConfigPage />} />
                <Route path="/promocode" element={<PromoCode />} />
                <Route path="/refund/generate" element={<GenerateRefund />} />
                <Route
                    path="/withdrawalrequest"
                    element={<WithdrawalRequest />}
                />
                <Route path="/promocode/:id" element={<SinglePromocode />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/smartlist" element={<SmartList />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/config" element={<ConfigPage />} />
                <Route path="/campaign/:id" element={<CampaignView />} />
                <Route path="/campaign" element={<Campaign />} />
                <Route path="/ratings" element={<OrderRatingsScreen />} />
                <Route
                    path="/customerdetails/:id"
                    element={<CustomerDetails />}
                />
                <Route path="/bikerental" element={<DeliveryBoyTable />} />
            </Routes>
        );
    };
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            {/* <CssBaseline /> */}
            <AppBar elevation={0} position="fixed" open={open}>
                <Toolbar
                    style={{
                        backgroundColor: "white",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                color: "#696969",
                                boxShadow: "none",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                            }}
                            src={logo}
                            id="logo"
                            alt="AkbLogo"
                        />
                        <div style={{ width: 20 }} />
                        <Typography
                            color={"black"}
                            variant="h6"
                            noWrap
                            component="div"
                        >
                            Admin Panel <strong>AKB</strong>
                        </Typography>
                    </div>
                    <button
                        style={{
                            cursor: "pointer",
                            backgroundColor: "#00000005",
                            border: "1px solid #0000001A",
                            padding: "12px 25px",
                            borderRadius: "15px",
                        }}
                        onClick={() => {
                            localStorage.removeItem("token2");
                            localStorage.removeItem("permissions");
                            window.location.reload();
                        }}
                    >
                        Logout
                    </button>
                </Toolbar>
            </AppBar>
            <Drawer
                style={{ borderRight: "none" }}
                variant="permanent"
                open={open}
            >
                <DrawerHeader
                    sx={{
                        backgroundColor: "white",
                        display: "flex",
                        borderRight: "none",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <div
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                            }}
                            src={UserSvg}
                            alt="user"
                            id="profileImage"
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: 14 }}>Aap Ka Bazar</div>
                        <div style={{ fontSize: 12 }}>Admin</div>
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <List style={{ borderRight: "none" }}>
                    {menu?.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                                paddingBottom: 0.5,
                            }}
                        >
                            <ListItemButton
                                onClick={() => {
                                    setSelectedContent(item.route);
                                    navigate(item.route, {
                                        state: {
                                            page: 1,
                                            snf: {
                                                sortField: "totalOrders",
                                                sortOrder: -1,
                                            },
                                        },
                                    });
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,

                                    backgroundColor:
                                        item.route === selectedContent
                                            ? "#ccc"
                                            : "transparent",
                                    "&:hover": {
                                        backgroundColor:
                                            item.route !== selectedContent
                                                ? "rgba(0,0,0,0.2)    "
                                                : "#ccc",
                                    },
                                }}
                            >
                                <img
                                    alt="hello"
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                    src={item.icon}
                                />
                                <ListItemText
                                    primary={item.title}
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        paddingLeft: 1,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flex: 1,
                }}
            >
                <DrawerHeader />
                {renderContent()}
            </Box>
        </Box>
    );
}
