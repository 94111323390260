import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataSec, postDataSec, putDataSec } from "../../Apis/Api";
import { baseURL, liveURL } from "../../config/config";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const SingleProductNewDescription = () => {
    const location = useLocation();
    const productDetail = location.pathname.split("/")[2];
    const categoryId = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [product, setProduct] = useState<any>();
    const [subCategories, setSubCategories] = useState<any>([]);
    const [leafCategories, setLeafCategories] = useState<any>();
    const [tree, setTree] = useState<any>([]);
    const [brands, setBrands] = useState<any>([]);
    const [subBrands, setSubBrands] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [imageArray, setImageArray] = useState<any>([]);
    const [previewLinks, setPreviewLinks] = useState<any>([]);
    const bulkUploadRef = React.useRef<any>(null);
    const [faq, setFaq] = useState([]);
    const [howToUse, setHowToUse] = useState("");
    const [benefits, setBenefits] = useState("");
    const [nutritionalFacts, setNutritionalFacts] = useState([]);
    const [description, setDescription] = useState("");
    const [gm, setGm] = useState("");
    const [manufacturerDetails, setManufacturerDetails] = useState("");
    const [fssaiNo, setFssaiNo] = useState("");
    const [country, setCountry]= useState("");

    const resetStateValues = () => {
        setProduct(undefined);
        setSubCategories([]);
        setLeafCategories(undefined);
        setTree([]);
        setBrands([]);
        setSubBrands([]);
        setLoading(false);
        setImageArray([]);
        setPreviewLinks([]);
    };

    const changePositionNutritionalFacts = (index, direction) => {
        let temp = [...nutritionalFacts];
        if (direction === "up") {
            if (index === 0) return;
            let temp1 = temp[index];
            temp[index] = temp[index - 1];
            temp[index - 1] = temp1;
        } else {
            if (index === temp.length - 1) return;
            let temp1 = temp[index];
            temp[index] = temp[index + 1];
            temp[index + 1] = temp1;
        }
        setNutritionalFacts(temp);
    }

    const renderDetails = () => {
        setProduct(null);
        setLoading(true);
        getDataSec(
            `${baseURL}/product?productId=${productDetail}&categoryId=${categoryId}`
        )
            .then((res) => {
                if (res.success) {
                    setProduct(res.product);
                    if (res?.product?.faq) setFaq(res.product.faq);
                    if(res?.product?.country) setCountry(res.product.country);
                    if(res?.product?.manufacturerDetails) setManufacturerDetails(res.product.manufacturerDetails);
                    if(res?.product?.fssaiNo) setFssaiNo(res.product.fssaiNo);
                    if (res?.product?.howToUse) setHowToUse(res.product.howToUse);
                    if (res?.product?.benefits) setBenefits(res.product.benefits);
                    if (res?.product?.nutritionalFacts) setNutritionalFacts(res.product.nutritionalFacts);
                    if (res?.product?.description) setDescription(res.product.description);
                    if (res?.product?.images?.length > 0) {
                        setImageArray(res.product?.images);
                        setPreviewLinks(
                            res.product.images.map((item) => ({
                                source: `${liveURL}/public/product/${res.product?.id}/${item}`,
                                name: item,
                            }))
                        );
                    }
                }
            })
            .catch((err) => {
                toast.error("Error in fetching categories");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        renderDetails();
    }, []);

    const handleUpdate = () => {
        putDataSec(`${baseURL}/product/update/v3`, {
            productId: product._id,
            product: {
                description: description,
                faq: faq,
                howToUse: howToUse,
                benefits: benefits,
                nutritionalFacts: nutritionalFacts,
                nutritionalBaseQuantity: gm,
                country: country,
                manufacturerDetails: manufacturerDetails, 
                fssaiNo: fssaiNo, 
            },
        })
            .then((res) => {
                if (res.success) {
                    toast.success("Product updated successfully.");
                    resetStateValues();
                    renderDetails();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCopyFrom = async (
        currentProductId,
        fieldName,
        setFieldValue
    ) => {
        const sourceProductId = prompt("Enter the Product ID to copy from:");

        if (!sourceProductId) {
            toast.error("No Product ID entered!");
            return;
        }

        try {
            const newProductDetail = await getDataSec(
                `${baseURL}/getProduct/${sourceProductId}`
            );

            if (newProductDetail.success) {
                const userConfirmation = window.confirm(
                    `The new ${fieldName} is: ${newProductDetail.product[fieldName]}\nClick OK to apply changes.`
                );

                if (userConfirmation) {
                    const response = await postDataSec(`${baseURL}/copyProduct`, {
                        currentProductId,
                        copyFromProductId: sourceProductId,
                        fieldsToCopy: [fieldName],
                    });

                    if (response.success) {
                        toast.success(`${fieldName} updated successfully!`);
                        window.location.reload();
                    } else {
                        toast.error("Failed to fetch updated data.");
                    }
                } else {
                    toast.info("Changes not applied.");
                }
            }
        } catch (error) {
            toast.error(`Error copying ${fieldName}`);
        }
    };

    return (
        <div style={{ padding: 10, backgroundColor: "white", display: "flex", gap: 10, margin: 20 }}>
            <div
                className="editModal"
                style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    maxWidth: "100%",
                    height: '90vh',
                    overflow: 'hidden',
                }}
            >
                <div style={{ height: '100%', overflow: 'scroll', minWidth: 400 }}>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                alignSelf: "center",
                                alignItems: "center",
                                flex: 1,
                                flexDirection: "row",
                                gap: 20,
                                marginTop: "20px",
                            }}
                        >
                            <h3>Loading Details</h3>
                            <CircularProgress
                                size={18}
                                sx={{ color: "#aaa" }}
                            />{" "}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                                flexDirection: "column",
                                gap: 20,
                                marginTop: "20px",
                            }}
                        >
                            <p style={{ textTransform: "capitalize" }}>
                                Id : {product?.id}
                            </p>
                            <p style={{ textTransform: "capitalize" }}>
                                Oid : {product?._id}
                            </p>
                            <p style={{ textTransform: "capitalize" }}>
                                {product?.name}
                            </p>
                            <div
                                style={{
                                    flexDirection: "row",
                                    gap: 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    style={{ width: 80 }}
                                    onClick={handleUpdate}
                                >
                                    Save
                                </button>
                                <button
                                    style={{ width: 80 }}
                                    onClick={() => window.close()}
                                >
                                    Close (X)
                                </button>
                            </div>
                            <div>
                                {product && (
                                    <div
                                        style={{
                                            display: "flex",
                                            border: "0px solid #0a3b64",
                                            margin: 20,
                                            padding: 20,
                                            flexWrap: "wrap",
                                            background: "#0a3b6422",
                                            gap: 20,
                                            borderRadius: 20,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {previewLinks.map((imgname, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        borderRadius: 12,
                                                        backgroundColor: "white",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            borderRadius: 12,
                                                            padding: 12,
                                                            height: 80,
                                                            width: 80,
                                                            background: "white",
                                                        }}
                                                        src={imgname.source}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {product && (
                    <div style={{ height: '100%', overflow: 'scroll' }}>
                        <table
                            border={1}
                            style={{ overflow: "hidden", marginBottom: 30 }}
                        >
                            <tbody>
                                <tr style={{ minHeight: 60 }}>
                                    <td>
                                        Product Description
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "description",
                                                        setDescription
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={setDescription}
                                        />
                                    </td>
                                </tr>

                                <tr style={{ minHeight: 50 }}>
                                    <td>
                                        Nutritional Facts
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "nutritionalFacts",
                                                        setNutritionalFacts
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>

                                    <td>
                                        <div style={{ margin: "8px" }}>
                                            <label>Serving Size : </label>
                                            <input
                                                type="text"
                                                value={gm || ""}
                                                onChange={(e) =>
                                                    setGm(e.target.value)
                                                }
                                                placeholder="Enter Portion Size"
                                            />
                                        </div>
                                        <table border={1}>
                                            <thead style={{ marginTop: "6px" }}>
                                                <tr>
                                                    <th>up/down</th>
                                                    <th>Name</th>
                                                    <th>Value</th>
                                                    <th>Metric</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {nutritionalFacts.length > 0 && <tbody>
                                                {nutritionalFacts.map((item, index) => {
                                                    return <tr>
                                                        <td>
                                                            <button onClick={() => changePositionNutritionalFacts(index, 'up')} >Up</button>
                                                            <button onClick={() => changePositionNutritionalFacts(index, 'down')} >Down</button>
                                                        </td>
                                                        <td>
                                                            <input type="text" value={item.name} onChange={(e) => {
                                                                let temp = [...nutritionalFacts];
                                                                temp[index].name = e.target.value;
                                                                setNutritionalFacts(temp);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="text" value={item.value} onChange={(e) => {
                                                                let temp = [...nutritionalFacts];
                                                                temp[index].value = e.target.value;
                                                                setNutritionalFacts(temp);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="text" value={item.metric} onChange={(e) => {
                                                                let temp = [...nutritionalFacts];
                                                                temp[index].metric = e.target.value;
                                                                setNutritionalFacts(temp);
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <button onClick={() => {
                                                                let temp = [...nutritionalFacts];
                                                                temp.splice(index, 1);
                                                                setNutritionalFacts(temp);
                                                            }} >Remove</button>
                                                        </td>
                                                    </tr>
                                                })
                                                }
                                            </tbody>
                                            }
                                        </table>
                                        <button onClick={() => {
                                            setNutritionalFacts([...nutritionalFacts, { name: '', value: '', metric: '' }])
                                        }} >Insert Row</button>
                                    </td>
                                </tr>
                                <tr style={{ minHeight: 50 }}>
                                    <td>
                                        How to Use
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "howToUse",
                                                        setHowToUse
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <ReactQuill
                                            theme="snow"
                                            value={howToUse}
                                            onChange={setHowToUse}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ minHeight: 50 }}>
                                    <td>
                                        Benefits
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "benefits",
                                                        setBenefits
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <ReactQuill
                                            theme="snow"
                                            value={benefits}
                                            onChange={setBenefits}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ minHeight: 50 }}>
                                    <td>
                                        FAQs
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "faq",
                                                        setFaq
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <table border={1}>
                                            <thead>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>Answer</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {faq.length > 0 && (
                                                <tbody>
                                                    {faq.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            item.question
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let temp =
                                                                                [
                                                                                    ...faq,
                                                                                ];
                                                                            temp[
                                                                                index
                                                                            ].question =
                                                                                e.target.value;
                                                                            setFaq(
                                                                                temp
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={
                                                                            item.answer
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let temp =
                                                                                [
                                                                                    ...faq,
                                                                                ];
                                                                            temp[
                                                                                index
                                                                            ].answer =
                                                                                e.target.value;
                                                                            setFaq(
                                                                                temp
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        onClick={() => {
                                                                            let temp =
                                                                                [
                                                                                    ...faq,
                                                                                ];
                                                                            temp.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                            setFaq(
                                                                                temp
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            )}
                                        </table>
                                        <button
                                            onClick={() => {
                                                setFaq([
                                                    ...faq,
                                                    { question: "", answer: "" },
                                                ]);
                                            }}
                                        >
                                            Insert Row
                                        </button>
                                    </td>
                                </tr>
                                <tr style={{ minHeight: 50 }}>
                                    <td>
                                        Country of Origin
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "country",
                                                        setCountry
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                    <div style={{ margin: "25px" }}>
                                            <input
                                                type="text"
                                                value={country || ""}
                                                onChange={(e) =>
                                                    setCountry(e.target.value)
                                                }
                                                placeholder="Enter Origin"
                                            />
                                    </div>
                                    </td>
                                    
                                    </tr>
                                   <tr style={{ minHeight: 50 }}>
                                     <td>Manufacturer Details
                                        <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        "manufacturerDetails ",
                                                        setManufacturerDetails 
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                     </td>
                                     <td>
                                       <div style={{ margin: "15px" }}>
                                        <input
                                          type="text"
                                          value={manufacturerDetails || ""}
                                          onChange={(e) => setManufacturerDetails(e.target.value)}
                                          placeholder="Enter Manufacturer Details"
                                          />
                                       </div>
                                    </td>
                                </tr>


                                <tr style={{ minHeight: 50 }}>
                                  <td>FSSAI License
                                       <div
                                            style={{
                                                padding: "6px",
                                                marginTop: "25px",
                                                background: "#f5f5f5",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleCopyFrom(
                                                        productDetail,
                                                        fssaiNo,
                                                        setFssaiNo
                                                    )
                                                }
                                                style={{
                                                    padding: "5px 10px",
                                                    background: "lightblue",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Copy From
                                            </button>
                                        </div>
                                        </td>
                                    <td>
                                     <div style={{ margin: "15px" }}>
                                       <input
                                         type="text"
                                         value={fssaiNo || ""}
                                         onChange={(e) => setFssaiNo(e.target.value)}
                                         placeholder="Enter FSSAI License Number"
                                    />
                                    </div>
                                    </td>
                                 </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

const handleSave = (product) => {
    if (!product?.name) {
        toast.error("Please enter product name");
        return;
    }
    if (!product?.description) {
        toast.error("Please enter product description");
        return;
    }
    if (!product?.rootCategory?._id) {
        toast.error("Please select root category");
        return;
    }
    if (!product?.subCategory?._id) {
        toast.error("Please select sub category");
        return;
    }
    if (!product?.leafCategory?._id) {
        toast.error("Please select leaf category");
        return;
    }
    if (!product?.brand?.id) {
        toast.error("Please select brand");
        return;
    }
    if (!product?.subBrand?.id) {
        toast.error("Please select sub brand");
        return;
    }
    if (!product?.recommendedAttribute) {
        toast.error("Please enter attribute");
        return;
    }
    if (!product?.shipping?.weight || product?.shipping?.weight === 0) {
        toast.error("Please enter weight");
        return;
    }
    if (!product?.hsnCode) {
        toast.error("Please enter HSN code");
        return;
    }
    if (!product?.barCode) {
        toast.error("Please enter barcode");
        return;
    }
    if (!product?.price) {
        toast.error("Please enter MRP");
        return;
    }
    if (!product?.sellPrice) {
        toast.error("Please enter sell price");
        return;
    }
    if (!product?.minSellPrice) {
        toast.error("Please enter min sell price");
        return;
    }
    if (!product?.seo?.metaTitle) {
        toast.error("Please enter meta title");
        return;
    }
    if (!product?.seo?.metaKeywords) {
        toast.error("Please enter meta keywords");
        return;
    }
    if (!product?.seo?.metaDescription) {
        toast.error("Please enter meta description");
        return;
    }
    if (!product?.description) {
        toast.error("Please enter product description");
        return;
    }
    postDataSec(baseURL + "/product/add", {
        barCode: product?.barCode,
        name: product?.name,
        description: product?.description,
        recommendedAttribute: product?.recommendedAttribute,
        mrp: product?.mrp,
        purchasePrice: 0,
        minSellPrice: product?.minSellPrice,
        price: product?.price,
        membershipPrice: 0,
        hsnCode: product?.hsnCode,
        gst: 0,
        urlKey: product?.name
            .replace(/[^\w\s]|_/g, "")
            .replace(/\s+/g, "-")
            .toLowerCase(),
        isOrder: true,
        isLastBuying: false,
        isSubscription: false,
        isMorningBuy: false,
        brand: product?.brand,
        subBrand: product?.subBrand,
        rootCatId: product?.rootCategory._id,
        subCatId: product?.subCategory._id,
        leafCatId: product?.leafCategory._id,
        images: [],
        tags: product?.tags,
        seo: {
            metaTitle: product?.metaTitle,
            metaDescription: product?.metaDescription,
            metaKeywords: product?.metaKeywords,
        },
        shipping: {
            dimensions: {
                height: 0,
                length: 0,
                width: 0,
            },
            weight: product?.shipping?.weight,
        },
        competitor: {
            grofers: {
                productId: null,
                lastPrice: null,
                merchantId: null,
            },
            milkbasket: {
                productId: null,
                lastPrice: null,
            },
            bigbasket: {
                productId: null,
                lastPrice: null,
            },
            grocio: {
                productId: null,
                lastPrice: null,
            },
        },
        parentId: "",
        isParent: true,
    })
        .then((res) => {
            if (res.success) {
                toast.success("Product added successfully.");
                // navigate('/products')
            }
        })
        .catch((err) => {
            console.log(err);
        });
};
